/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
	Home as HomeIcon,
	Schedule as PendingUser,
	AssignmentInd as SellerIcon,
	Assignment as PlanIcon,
	Person as UserIcon,
	Receipt as Raffles,
	HourglassFull as PendingPrizesIcon,
	LocalActivity as RaffleResult,
	ChromeReaderMode as BannerIcon,
	QuestionAnswer as FAQIcon,
	AttachMoney as HistoryTransferIcon,
} from "@material-ui/icons";
import Strings from "../modules/Strings";

export function useMainRoutes() {
	const commonRoutes = [
		{
			Icon: HomeIcon,
			text: Strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: PendingUser,
			text: Strings.nav.pendingUsers,
			path: "/dashboard/pendingUsers",
		},
		{
			Icon: UserIcon,
			text: Strings.nav.users,
			path: "/dashboard/users",
		},
		{
			Icon: SellerIcon,
			text: Strings.nav.sellers,
			path: "/dashboard/sellers",
		},
		{
			Icon: PlanIcon,
			text: Strings.nav.plan,
			path: "/dashboard/plans",
		},
		{
			Icon: Raffles,
			text: Strings.nav.raffles,
			path: "/dashboard/raffles",
		},
		{
			Icon: RaffleResult,
			text: Strings.nav.rafflesResult,
			path: "/dashboard/rafflesResult",
		},
		{
			Icon: BannerIcon,
			text: Strings.nav.banners,
			path: "/dashboard/banners",
		},
		{
			Icon: PendingPrizesIcon,
			text: Strings.nav.pendingPrizes,
			path: "/dashboard/pendingPrizes",
		},
		{
			Icon: FAQIcon,
			text: Strings.nav.faq,
			path: "/dashboard/faq",
		},
		{
			Icon: HistoryTransferIcon,
			text: Strings.nav.transferHistory,
			path: "/dashboard/transferHistory",
		},
	];

	return commonRoutes;
}
