import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { makeObservable, observable } from "mobx";

import UIStore from "../../../../stores/UIStore";
import LoaderShelf from "../../../../shelves/LoaderShelf";

export default class Store {
	private router: IRouterPusher;
	private uiStore: UIStore;

	public loader: LoaderShelf;
	public pendingUser: API.User | null = null;

	constructor(uiStore: UIStore, id: string, router: IRouterPusher) {
		this.router = router;
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		this.getPendingUser(id);
		makeObservable(this, {
			pendingUser: observable,
		});
	}

	public getPendingUser = async (id: string) => {
		this.loader.start();
		try {
			this.pendingUser = await API.getUser(id);
		} catch (error) {
			this.uiStore.showSuccessSnackbar();
		}finally {
			this.loader.end();
		}
	};

	public approvedOrrefuse = async (onSucess: () => void, statusSeller: API.StatusSeller) => {
		this.loader.start();

		try {
			if (this.pendingUser) {
				this.pendingUser = await API.setApproveOrRefuse(this.pendingUser.id, statusSeller);
				this.uiStore.showSuccessSnackbar();
				onSucess();
			}
		} catch (error) {
			this.uiStore.showErrorSnackbar(error.message);
		}finally {
			this.loader.end();
		}
	};
}
