import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import { format } from "date-fns";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, history));

	return (
		<CardForm title={Strings.sellers.details.title}>
			{!store.seller ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow label={Strings.fields.name} value={store.seller.name} />
					<DetailRow label={Strings.fields.email} value={store.seller.email} />
					{store.seller.cpf && (
						<DetailRow label={Strings.fields.cpf} value={store.seller.cpf} />
					)}
					{store.seller.birthdate && (
						<DetailRow
							label={Strings.fields.birthdate}
							value={format(store.seller.birthdate, "dd/MM/yyyy")}
						/>
					)}
					<DetailRow label={Strings.fields.phone} value={store.seller.phone} />
					{store.seller.address && (
						<>
							<DetailRow label={Strings.fields.state} value={store.seller.address.state} />
							<DetailRow label={Strings.fields.city} value={store.seller.address.city} />
							<DetailRow label={Strings.fields.neighborhood} value={store.seller.address.neighborhood} />
							<DetailRow label={Strings.fields.street} value={store.seller.address.street} />
							<DetailRow label={Strings.fields.streetNumber} value={store.seller.address.streetNumber} />
							<DetailRow label={Strings.fields.zipCode} value={store.seller.address.zipcode} />
						</>
					)}
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
