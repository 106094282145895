import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import DetailsView from "./DetailsView";
import TicketDetailsView from "./TicketDetailsView";

interface IProps { }

const Raffle: React.FC<IProps> = () => (
	<Switch>
		<Route
			exact
			path="/dashboard/raffles"
			component={TableView}
		/>
		<Route
			path="/dashboard/raffles/details/:id"
			component={DetailsView}
		/>
		<Route
			path="/dashboard/raffles/ticketDetails/:id"
			component={TicketDetailsView}
		/>
	</Switch>
);

export default observer(Raffle);
