import { makeAutoObservable } from "mobx";

import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";

import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

export default class Store {
	private uiStore: UIStore;

	public loader: LoaderShelf;
	public fieldError: FieldErrorShelf<API.NewFAQ>;

	public formController = new FormStore({
		answer: "",
		question: "",
	});

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		this.fieldError = new FieldErrorShelf();
		makeAutoObservable(this);
	}

	public create = async (onSuccessCallback: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			const {
				answer,
				question,
			} = this.formController.getValues();

			if (question.length < 8) {
				this.fieldError.addError({
					message: Strings.error.shortName,
					field: "question",
				});
				return;
			}

			if (answer.length < 8) {
				this.fieldError.addError({
					message: Strings.error.shortName,
					field: "answer",
				});
				return;
			}

			this.loader.start();
			await API.createFAQ({
				answer,
				question,
			});

			onSuccessCallback();
			this.uiStore.showSnackbar(Strings.FAQ.create.success);
		} catch (error) {
			this.fieldError.clearErrors();
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
