import { makeAutoObservable } from "mobx";

import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";

import LoaderShelf from "../../../../shelves/LoaderShelf";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";

export default class Store {
	private uiStore: UIStore;

	public loader: LoaderShelf;
	public fieldError: FieldErrorShelf<API.RaffleResult>;
	public prizeDate: Date | Date = new Date();
	public formController = new FormStore({
		first: "",
		second: "",
		third: "",
		fourth: "",
		fifth: "",
		contestNumber: "",
	});

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		makeAutoObservable(this);
	}

	public create = async (onSuccessCallback: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();
			const data = this.formController.getValues();
			await API.addRaffleResult({
				contestNumber: parseInt(data.contestNumber),
				first: parseInt(data.first),
				second: parseInt(data.second),
				third: parseInt(data.third),
				fourth: parseInt(data.fourth),
				fifth: parseInt(data.fifth),
				prizeDate: this.prizeDate,
			});

			onSuccessCallback();
			this.uiStore.showSnackbar(Strings.rafflesResult.success);
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};

	public setPrizeDate = (date: Date) => {
		this.prizeDate = date;
	};
}
