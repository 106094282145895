import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(1),
		},

		textField: {
			margin: theme.spacing(1),
			maxWidth: 200,
			width: "100%",
		},
		fieldSelect: {
			margin: theme.spacing(1),
			maxWidth: 200,
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				maxWidth: 200,
				margin: theme.spacing(1),
			},
		},
		fileCases: {
			margin: theme.spacing(2),
			marginTop: theme.spacing(5),
			maxWidth: 600,
		},
		largeField: {
			margin: theme.spacing(0.8),
			width: "100%",
			maxWidth: 600,
			[theme.breakpoints.down("xs")]: {
				maxWidth: 300,
				width: "100%",
			},
		},
		dangerText: {
			color: "#D50000",
		},
		chip: {
			margin: theme.spacing(0.5),
		},
		tag: {
			width: "100%",
			maxWidth: "200px",
		},
	}),
);

export default useStyles;
