import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import CardImages from "../../../../components/CardImages";
import LinearProgress from "../../../../components/LinearProgress";
import { IconButton, TableCell, TableRow, Chip } from "@material-ui/core";
import { Formatting } from "../../../../modules/Formatting";
import API from "../../../../modules/API";
import SimpleTable from "../../../../components/SimpleTable";
import { useGlobalStore } from "../../../context";
import { Visibility } from "@material-ui/icons";
import { useStyles } from "./styles";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const classes = useStyles();

	const pushToDetails = (idTicket: string) => (
		history.push(`/dashboard/raffles/ticketDetails/${idTicket}`)
	);

	return (
		<CardForm title={Strings.raffles.details.title}>
			{!store.raffle ? (
				<LinearProgress />
			) : (
				<>
					{store.raffle.finished
						? <Chip label={Strings.raffles.details.finished} className={classes.tagFinished} />
						: <Chip label={Strings.raffles.details.onWay} className={classes.tagPending} />
					}
					<DetailRow label={Strings.fields.name} value={store.raffle.title} />
					<DetailRow label={Strings.fields.price} value={Formatting.currencyForBR(store.raffle.ticketPrice)} />
					<DetailRow label={Strings.fields.amountOfPrizes} value={store.raffle.amountOfPrizes} />
					<DetailRow label={Strings.fields.amountOfTicketSolds} value={store.raffle.ticketsSold} />
					<DetailRow label={Strings.fields.goal} value={`${store.raffle.goal}%`} />
					<DetailRow multiline label={Strings.fields.objective} value={store.raffle.objective} />
					<DetailRow label={Strings.fields.createdAt} value={Strings.fields.shortDate(store.raffle.createdAt)} />
					<DetailRow label={Strings.fields.endAt} value={Strings.fields.shortDate(store.raffle.endDate)} />
					{store.raffle.images.length > 0 && (
						<CardImages images={store.raffle.images.map((image: API.Image) => image.url)} />
					)}
					<SimpleTable
						loading={store._loading}
						title={Strings.tickets.table.title}
						pagination={store.getPagination()}
						isHaveNexPage={store._isHaveNextPage}
						rows={store._items}
						headers={[
							Strings.fields.id,
							Strings.fields.number,
							Strings.fields.status,
							Strings.components.table.details,
						]}
						renderItem={(item) => (
							<TableRow hover key={item.id}>
								<TableCell align={"center"}>{item.id}</TableCell>
								<TableCell align={"center"}>{item.number}</TableCell>
								<TableCell align={"center"}>{API.translateTicketStatus(item.status)}</TableCell>
								<TableCell align={"center"}>
									<IconButton onClick={() => pushToDetails(item.id)}>
										<Visibility />
									</IconButton>
								</TableCell>
							</TableRow>
						)}
					/>
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
