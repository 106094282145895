import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import { Formatting } from "../../../../modules/Formatting";
import useClipboard from "react-use-clipboard";
import { useStyles } from "./styles";
import { Button } from "@material-ui/core";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const store = useLocalStore(() => new Store(id));

	const classes = useStyles();

	const setLinkforReceivePrize = () => {
		if (process.env.WEB_URL) {
			return `${process.env.WEB_URL}/receivePrize/${id}`;
		} else {
			return Strings.fields.notFound;
		}
	};

	const [isCopied, setCopied] = useClipboard(setLinkforReceivePrize(), {
		successDuration: 2000,
	});

	return (
		<CardForm title={Strings.pendingPrizes.details.title}>
			{!store.drawTicket ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow label={Strings.fields.nameWinner} value={store.drawTicket.raffleTicket.owner.name} />
					<DetailRow label={Strings.fields.email} value={store.drawTicket.raffleTicket.owner.email} />
					<DetailRow label={Strings.fields.phone} value={store.drawTicket.raffleTicket.owner.phone} />
					<DetailRow label={Strings.fields.nameRaffle} value={store.drawTicket.raffle.title} />
					<DetailRow label={Strings.fields.price} value={Formatting.currencyForBR(store.drawTicket.raffle.ticketPrice)} />
					<DetailRow label={Strings.fields.amountOfPrizes} value={store.drawTicket.raffle.amountOfPrizes} />
					<DetailRow multiline label={Strings.fields.objective} value={store.drawTicket.raffle.objective} />
					<DetailRow label={Strings.fields.createdAt} value={Strings.fields.shortDate(store.drawTicket.raffle.createdAt)} />
					<DetailRow label={Strings.fields.endAt} value={Strings.fields.shortDate(store.drawTicket.raffle.endDate)} />
					<Button variant="contained" onClick={setCopied} className={classes.buttonCopy} color="primary">
						{Strings.pendingPrizes.details.copyCode(isCopied)}
					</Button>
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
