import UIStore from "../../../../stores/UIStore";
import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { action, makeObservable, observable } from "mobx";
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import PaginatedListStore from "../../../../stores/PaginatedListStore";

export default class Store extends PaginatedListStore<API.Raffle> {
	public transferHistory: API.TransferHistory | null = null;
	private uiStore: UIStore;
	private transferId = "";


	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.transferId = id;
		this.fetchPage(0);
		this.uiStore = uiStore;
		this.getTransferHistory(id);
		makeObservable(this, {
			transferHistory: observable,
			fetchPage: action,
			getTransferHistory: action,
			confirmTransfer: action,
		});
	}

	protected getDataItemsPerPage(): Promise<API.Raffle[]> {
		return API.getRafflesByTransferHistory(this.transferId);
	}

	public getTransferHistory = async (id: string) => {
		try {
			this.transferHistory = await API.getTransferHistory(id);
		} catch (error) {
			this.uiStore.showErrorSnackbar(Errors.handleError(error));
		}
	};

	public confirmTransfer = async (id: string) => {
		try {
			await API.transferTheRequestAvailableRaffles(id);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};
}
