import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));
	const history = useHistory();
	const onSubmit = async () => {
		await store.create(() => history.goBack());
	};

	const handleDateChange = (date: Date) => {
		store.setPrizeDate(date);
	};

	const disableWeekends = (date: Date) => date.getDay() !== 3 && date.getDay() !== 6;

	return (
		<CardForm
			title={Strings.rafflesResult.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmit}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.plan.create.createButton
					)}
				</Button>
			}
		>
			<CardContent>
				<TextField
					disabled={store.loader.isLoading}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.firstPrize}
					{...store.formController.field("first")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.secondPrize}
					{...store.formController.field("second")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.thirdPrize}
					{...store.formController.field("third")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.fourthPrize}
					{...store.formController.field("fourth")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.fifthPrize}
					{...store.formController.field("fifth")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.contestNumber}
					{...store.formController.field("contestNumber")}
				/>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disabled={store.loader.isLoading}
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy"
						style={{ marginTop: 8}}
						id="prizeDate"
						name="prizeDate"
						shouldDisableDate={disableWeekends}
						label={Strings.fields.prizeDate}
						value={store.prizeDate}
						onChange={(date) => {
							if (date) {
								handleDateChange(date);
							}
						}}
						KeyboardButtonProps={{
							"aria-label": "change date",
						}}
					/>
				</MuiPickersUtilsProvider>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
