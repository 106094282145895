import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
	Box,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";
import FileCase from "../../../../components/FileCase";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));

	const history = useHistory();
	const onSubmit = async () => {
		await store.create(() => history.goBack());
	};

	return (
		<CardForm
			title={Strings.banner.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmit}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.banner.create.createButton
					)}
				</Button>
			}
		>
			<CardContent>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("title")}
					helperText={store.fieldError.getFieldError("title")}
					className={classes.textField}
					variant="standard"
					type="text"
					label={Strings.fields.title}
					{...store.formController.field("title")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("message")}
					helperText={store.fieldError.getFieldError("message")}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.message}
					{...store.formController.field("message")}
				/>
				<Box className={classes.fileCases}>
					<FileCase title={Strings.fields.image} {...store.image.toCase()} />
					<FileCase title={Strings.fields.imageContainer} {...store.contentImage.toCase()} />
				</Box>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
