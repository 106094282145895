import { makeObservable, observable, runInAction } from "mobx";

import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";

import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";

import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import FileStore from "../../../../stores/FileStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export default class Store extends ModelStore<API.Banner | API.NewBanner> {
	protected router: IRouteReplacer;

	public fieldError: FieldErrorShelf<API.Banner>;
	public loader: LoaderShelf;
	public contentImage: FileStore;
	public image: FileStore;

	public formController = new FormStore({
		title: "",
		message: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		makeObservable(this, {
			formController: observable,
		});
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
		this.image = new FileStore(this.uiStore, "image");
		this.contentImage = new FileStore(this.uiStore, "image");
	}

	protected getModel(id: string) {
		return API.getBannerById(id);
	}

	protected afterModelFetch(model: API.Banner) {
		runInAction(() => {
			this.formController = new FormStore({
				title: model.title || "",
				message: model.message || "",
			});
			this.image = new FileStore(this.uiStore, "image", model.image);

			if (model.contentImage) {
				this.contentImage = new FileStore(this.uiStore, "image", model.contentImage);
			}
		});
	}

	public edit = async () => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}
			const image = this.image.getUncertainfiedImage();
			const contentImage = this.contentImage.getUncertainfiedImage();

			if (!image) {
				return this.uiStore.showErrorSnackbar(
					Strings.banner.edit.error,
				);
			}

			this.loader.start();
			this.fieldError.clearErrors();

			const { title, message } = this.formController.getValues();
			const banner = await API.editBanner(this.id, {
				title,
				message,
				image,
				contentImage,
			});
			this.router.replace("/dashboard/banners");
			this.router.go(0);
			this.uiStore.showSnackbar(Strings.banner.edit.success(banner.title));
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
