import API from "../../../../modules/API";
import { action, makeObservable, observable } from "mobx";
import UIStore from "../../../../stores/UIStore";

export default class Store {
	private uiStore: UIStore;
	public drawTicket: API.DrawnTicket | null = null;
	public raffleId = "";

	constructor(id: string) {
		this.getPrizeConfirmation(id);
		this.raffleId = id;
		makeObservable(this, {
			drawTicket: observable,
			getPrizeConfirmation: action,
		});
	}

	public getPrizeConfirmation = async (id: string) => {
		this.drawTicket = await API.getPrizeConfirmation(id);
	};
}
