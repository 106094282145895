import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { action, makeObservable, observable } from "mobx";

import UIStore from "../../../../stores/UIStore";
import LoaderShelf from "../../../../shelves/LoaderShelf";

export default class Store {
	private router: IRouterPusher;
	private uiStore: UIStore;

	public loader: LoaderShelf;
	public ticket: API.Ticket | null = null;

	constructor(uiStore: UIStore, id: string, router: IRouterPusher) {
		this.router = router;
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		this.getTicket(id);
		makeObservable(this, {
			ticket: observable,
			getTicket: action,
		});
	}

	public getTicket = async (id: string) => {
		this.loader.start();
		try {
			this.ticket = await API.getTicket(id);
		} catch (error) {
			this.uiStore.showSuccessSnackbar();
		}finally {
			this.loader.end();
		}
	};
}
