import api from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class UserTableStore extends PaginatedListStore<api.User> {

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.User[]> {
		return api.getAllPendingSellers(page);
	}
}
