import React, { useCallback } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import { Formatting } from "../../../../modules/Formatting";
import { useStyles } from "./styles";
import {
	Box,
	Divider,
	Button,
} from "@material-ui/core";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { useGlobalStore } from "../../../../pages/context";
import SimpleTable from "../../../../components/SimpleTable";
import { Visibility } from "@material-ui/icons";
import API from "../../../../modules/API";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const history = useHistory();
	const classes = useStyles();

	const handleConfirmTransfer = useCallback(
		(name: string) => {
			uiStore.showDialog(
				{
					message: Strings.transferHistory.dialogConfirmTransfer.message(name),
					title: Strings.transferHistory.dialogConfirmTransfer.title,
				},
				() => store.confirmTransfer(id),
			);
		},
		[uiStore, store],
	);

	const pushToDetails = (idRaffle: string) => (
		history.push(`/dashboard/raffles/details/${idRaffle}`)
	);

	return (
		<CardForm title={Strings.transferHistory.details.title}>
			{!store.transferHistory ? (
				<LinearProgress />
			) : (
				<>
					<Box className={classes.title}>
						{Strings.transferHistory.details.transferData}
					</Box>
					<Divider className={classes.divider} />
					<DetailRow label={Strings.fields.dateTransfer} value={Strings.fields.shortDate(store.transferHistory.transferDate)} />
					<DetailRow label={Strings.fields.totalPrice} value={Formatting.currencyForBR(store.transferHistory.totalPrice)} />
					<Box className={classes.title}>
						{Strings.transferHistory.details.userData}
					</Box>
					<Divider className={classes.divider} />
					<DetailRow label={Strings.fields.name} value={store.transferHistory.user.name} />
					<DetailRow label={Strings.fields.email} value={store.transferHistory.user.email} />
					{store.transferHistory.user.cpf && (
						<DetailRow label={Strings.fields.cpf} value={store.transferHistory.user.cpf} />
					)}
					<DetailRow label={Strings.fields.phone} value={store.transferHistory.user.phone} />
					<Box className={classes.title}>
						{Strings.transferHistory.details.bankAccount}
					</Box>
					<Divider className={classes.divider} />
					<DetailRow label={Strings.fields.bankCode} value={store.transferHistory.user.seller?.bankAccount?.bankCode} />
					<DetailRow label={Strings.fields.account} value={store.transferHistory.user.seller?.bankAccount?.account} />
					{store.transferHistory.user.seller?.bankAccount?.accountDv && (
						<DetailRow label={Strings.fields.accountDV} value={store.transferHistory.user.seller?.bankAccount?.accountDv} />
					)}
					<DetailRow label={Strings.fields.agency} value={store.transferHistory.user.seller?.bankAccount?.agency} />
					{store.transferHistory.user.seller?.bankAccount?.agencyDv && (
						<DetailRow label={Strings.fields.agencyDV} value={store.transferHistory.user.seller?.bankAccount?.agencyDv} />
					)}
					{store.transferHistory.user && store.transferHistory.user.seller && store.transferHistory.user.seller.bankAccount && (
						<DetailRow label={Strings.fields.typeAccount} value={API.translateBankAccountType(store.transferHistory.user.seller.bankAccount.type)} />
					)}
					<Button variant="contained" color="primary" onClick={() => handleConfirmTransfer(store.transferHistory ? store.transferHistory.user.name : Strings.fields.user)} className={classes.button}>
						{Strings.transferHistory.details.confirmTransfer}
					</Button>
					<SimpleTable
						loading={store._loading}
						title={Strings.raffles.table.title}
						rows={store._items}
						headers={[
							Strings.fields.id,
							Strings.fields.name,
							Strings.fields.price,
							Strings.fields.amountOfPrizes,
							Strings.fields.createdAt,
							Strings.fields.endAt,
							Strings.components.table.details,
						]}
						renderItem={(item) => (
							<TableRow hover key={item.id}>
								<TableCell align={"center"}>{item.id}</TableCell>
								<TableCell align={"center"}>{item.title}</TableCell>
								<TableCell align={"center"}>{Formatting.currencyForBR(item.ticketPrice)}</TableCell>
								<TableCell align={"center"}>{item.amountOfPrizes}</TableCell>
								<TableCell align={"center"}>{Strings.fields.shortDate(item.createdAt)}</TableCell>
								<TableCell align={"center"}>{Strings.fields.shortDate(item.endDate)}</TableCell>
								<TableCell align={"center"}>
									<IconButton onClick={() => pushToDetails(item.id)}>
										<Visibility />
									</IconButton>
								</TableCell>
							</TableRow>
						)}
					/>
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
