import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import { Formatting } from "../../../../modules/Formatting";
import Store from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { Visibility, Delete } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import { Filter } from "../../../../components/Filter";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new Store(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`raffles/details/${id}`),
		[history],
	);

	const handleDeleteLesson = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.raffles.delete.message,
					title: Strings.raffles.delete.title,
				},
				() => tableStore.delete(id),
			);
		},
		[uiStore, tableStore],
	);

	const onFilter = () => {
		tableStore.fetchPage(0);
	};

	return (
		<PageTableContainer>
			<Filter
				{...tableStore.formshelf.field("name")}
				onClick={onFilter}
				placeHolder={Strings.raffles.filterPlaceHolder}
			/>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.raffles.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.name,
					Strings.fields.price,
					Strings.fields.amountOfPrizes,
					Strings.fields.amountOfTicketSolds,
					Strings.fields.createdAt,
					Strings.fields.endAt,
					Strings.components.table.detailsAndDelete,
				]}
				renderItem={(item) => (
					<TableRow hover key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.title}</TableCell>
						<TableCell align={"center"}>{Formatting.currencyForBR(item.ticketPrice)}</TableCell>
						<TableCell align={"center"}>{item.amountOfPrizes}</TableCell>
						<TableCell align={"center"}>{item.ticketsSold}</TableCell>
						<TableCell align={"center"}>{Strings.fields.shortDate(item.createdAt)}</TableCell>
						<TableCell align={"center"}>{Strings.fields.shortDate(item.endDate)}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
							<IconButton onClick={() => handleDeleteLesson(item.id)}>
								<Delete />
							</IconButton>

						</TableCell>
					</TableRow>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
