import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { action, makeObservable, observable } from "mobx";
import UIStore from "../../../../stores/UIStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { Errors } from "../../../../modules/Errors";

export default class Store extends PaginatedListStore<API.Ticket> {
	private uiStore: UIStore;
	public raffle: API.Raffle | null = null;
	public raffleId = "";

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.getRaffle(id);
		this.raffleId = id;
		this.fetchPage(0);
		makeObservable(this, {
			raffle: observable,
			raffleId: observable,
			fetchPage: action,
			getRaffle: action,
		});
	}

	protected getDataItemsPerPage = (page: number): Promise<API.Ticket[]> => (
		API.getRaffleTickets(this.raffleId, page)
	);

	public getRaffle = async (id: string) => {
		try {
			this.raffle = await API.getRaffle(id);
		} catch (error) {
			this.uiStore.showErrorSnackbar(Errors.handleError(error));
		}
	};

}
