import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			marginTop: theme.spacing(2),
		},
		textEllipsis: {
			whiteSpace: "nowrap",
			width: "28vw",
			justifyContent: "center",
			fontSize: "14px",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	}),
);
