import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface Banner {
    id: string;
    title: string;
    message: string;
    image: Image;
    contentImage: Image | null;
}

export interface PrizeResults {
    first: number;
    second: number;
    third: number;
    fourth: number;
    fifth: number;
}

export interface Statistics {
    raffleTotalPrice: number;
    totalSoldExternal: number;
    totalSoldCreditCard: number;
    totalSoldPix: number;
    totalSoldCreditBoleto: number;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
    latLng: LatLng | null;
}

export interface Transaction {
    id: string;
    boletoUrl: string | null;
    boletoBarcode: string | null;
    boletoExpirationDate: string | null;
    paymentMethod: PaymentMethod;
    status: TransactionStatus;
    statusReason: string;
    metadata: string;
    pixQRCode: string | null;
    qrExpirationDate: string | null;
    createdAt: Date;
    amount: number;
    lastFourDigits: string | null;
    cardBrand: string | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface FAQ {
    id: string;
    question: string;
    answer: string;
}

export interface NewFAQ {
    answer: string;
    question: string;
}

export interface AdminUser {
    id: string;
    name: string;
    image: Image | null;
    email: string;
    createdAt: Date;
}

export interface BankAccount {
    id: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string | null;
    type: BankAccountType;
}

export interface NewBanner {
    title: string;
    message: string;
    image: UncertainImage;
    contentImage: UncertainImage | null;
}

export interface User {
    id: string;
    email: string;
    name: string;
    image: Image | null;
    phone: string;
    cpf: string | null;
    birthdate: Date | null;
    seller: Seller | null;
    address: Address | null;
    createdAt: Date;
}

export interface Seller {
    documentFrontImage: Image;
    documentBackImage: Image;
    photoHoldingDocument: Image;
    approved: StatusSeller;
    bankAccount: BankAccount | null;
}

export interface Plan {
    id: string;
    title: string;
    limit: number;
    price: number;
}

export interface Raffle {
    id: string;
    owner: User;
    plan: Plan;
    title: string;
    objective: string;
    images: Image[];
    ticketPrice: number;
    endDate: Date;
    goal: number;
    amountOfPrizes: number;
    prizes: string[];
    forceResult: boolean;
    finished: boolean;
    ticketsSold: number;
    transferStatus: TransferStatus;
    planPaymentStatus: RafflePlanPaymentStatus;
    createdAt: Date;
}

export interface RaffleWithTransaction {
    id: string;
    owner: User;
    plan: Plan;
    title: string;
    objective: string;
    images: Image[];
    ticketPrice: number;
    endDate: Date;
    goal: number;
    amountOfPrizes: number;
    prizes: string[];
    forceResult: boolean;
    finished: boolean;
    ticketsSold: number;
    transferStatus: TransferStatus;
    createdAt: Date;
    planPaymentStatus: RafflePlanPaymentStatus;
    transaction: Transaction;
}

export interface RaffleAvailableForWithdrawal {
    raffle: Raffle;
    availableValue: number;
}

export interface Ticket {
    id: string;
    number: number;
    status: TicketStatus;
    saleType: TicketSaleType;
    owner: TicketOwner;
}

export interface TicketOwner {
    id: string | null;
    image: Image | null;
    name: string;
    phone: string;
    email: string;
}

export interface DrawnTicket {
    id: string;
    prizeNumber: number;
    forcedResult: boolean;
    raffleTicket: Ticket;
    confirmed: boolean;
    raffle: Raffle;
}

export interface NewPlan {
    title: string;
    limit: number;
    price: number;
}

export interface EditPlan {
    title: string;
    limit: number;
}

export interface RaffleResult {
    id: string;
    first: number;
    second: number;
    third: number;
    fourth: number;
    fifth: number;
    prizeDate: Date;
    contestNumber: number;
}

export interface NewRaffleResult {
    first: number;
    second: number;
    third: number;
    fourth: number;
    fifth: number;
    prizeDate: Date;
    contestNumber: number;
}

export interface NewRaffle {
    title: string;
    objective: string;
    images: UncertainImage[];
    ticketPrice: number;
    endDate: Date;
    goal: number;
    prizes: string[];
    forceResult: boolean;
    planId: string;
}

export interface EditFullRaffle {
    title: string;
    objective: string;
    images: UncertainImage[];
    endDate: Date;
    goal: number;
}

export interface EditRestrictedRaffle {
    images: UncertainImage[];
    goal: number;
}

export interface RaffleTicketsStatistics {
    purchased: number;
    reserved: number;
}

export interface NewUser {
    email: string;
    password: string;
    name: string;
    image: UncertainImage | null;
    phone: string;
    cpf: string | null;
    birthdate: Date | null;
}

export interface EditUser {
    email: string;
    name: string;
    image: UncertainImage | null;
    phone: string;
    cpf: string | null;
    birthdate: Date | null;
}

export interface NewAddress {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
    latLng: LatLng | null;
}

export interface NewSeller {
    documentFrontImage: UncertainImage;
    documentBackImage: UncertainImage;
    photoHoldingDocument: UncertainImage;
}

export interface TransferHistory {
    id: string;
    totalPrice: number;
    user: User;
    transferHistoryStatus: TransferHistoryStatus;
    transferDate: Date;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
    contaCorrenteConjunta = "contaCorrenteConjunta",
    contaPoupancaConjunta = "contaPoupancaConjunta",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
        case BankAccountType.contaCorrenteConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrenteConjunta"] || BankAccountType.contaCorrenteConjunta : BankAccountType.contaCorrenteConjunta;
        }
        case BankAccountType.contaPoupancaConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupancaConjunta"] || BankAccountType.contaPoupancaConjunta : BankAccountType.contaPoupancaConjunta;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
        BankAccountType.contaCorrenteConjunta,
        BankAccountType.contaPoupancaConjunta,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
        translateBankAccountType(BankAccountType.contaCorrenteConjunta),
        translateBankAccountType(BankAccountType.contaPoupancaConjunta),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum TransferStatus {
    available = "available",
    processing = "processing",
    draw = "draw",
    notAvailable = "notAvailable",
}

export function translateTransferStatus(enumTransferStatus: TransferStatus): string {
    switch (enumTransferStatus) {
        case TransferStatus.available: {
            return strings ? strings["enum"]["TransferStatus"]["available"] || TransferStatus.available : TransferStatus.available;
        }
        case TransferStatus.processing: {
            return strings ? strings["enum"]["TransferStatus"]["processing"] || TransferStatus.processing : TransferStatus.processing;
        }
        case TransferStatus.draw: {
            return strings ? strings["enum"]["TransferStatus"]["draw"] || TransferStatus.draw : TransferStatus.draw;
        }
        case TransferStatus.notAvailable: {
            return strings ? strings["enum"]["TransferStatus"]["notAvailable"] || TransferStatus.notAvailable : TransferStatus.notAvailable;
        }
    }
    return "";
}

export function allValuesTransferStatus(): TransferStatus[] {
    return [
        TransferStatus.available,
        TransferStatus.processing,
        TransferStatus.draw,
        TransferStatus.notAvailable,
    ];
}

export function allTranslatedValuesTransferStatus(): string[] {
    return [
        translateTransferStatus(TransferStatus.available),
        translateTransferStatus(TransferStatus.processing),
        translateTransferStatus(TransferStatus.draw),
        translateTransferStatus(TransferStatus.notAvailable),
    ];
}

export function allDisplayableValuesTransferStatus(): string[] {
    return allTranslatedValuesTransferStatus().sort();
}

export function valueFromTranslationTransferStatus(translation: string): TransferStatus {
    const index = allTranslatedValuesTransferStatus().indexOf(translation);
    return allValuesTransferStatus()[index] || TransferStatus.available;
}

export enum FilterOrder {
    desc = "desc",
    asc = "asc",
}

export function translateFilterOrder(enumFilterOrder: FilterOrder): string {
    switch (enumFilterOrder) {
        case FilterOrder.desc: {
            return strings ? strings["enum"]["FilterOrder"]["desc"] || FilterOrder.desc : FilterOrder.desc;
        }
        case FilterOrder.asc: {
            return strings ? strings["enum"]["FilterOrder"]["asc"] || FilterOrder.asc : FilterOrder.asc;
        }
    }
    return "";
}

export function allValuesFilterOrder(): FilterOrder[] {
    return [
        FilterOrder.desc,
        FilterOrder.asc,
    ];
}

export function allTranslatedValuesFilterOrder(): string[] {
    return [
        translateFilterOrder(FilterOrder.desc),
        translateFilterOrder(FilterOrder.asc),
    ];
}

export function allDisplayableValuesFilterOrder(): string[] {
    return allTranslatedValuesFilterOrder().sort();
}

export function valueFromTranslationFilterOrder(translation: string): FilterOrder {
    const index = allTranslatedValuesFilterOrder().indexOf(translation);
    return allValuesFilterOrder()[index] || FilterOrder.desc;
}

export enum StatusSeller {
    pending = "pending",
    rejected = "rejected",
    accepted = "accepted",
}

export function translateStatusSeller(enumStatusSeller: StatusSeller): string {
    switch (enumStatusSeller) {
        case StatusSeller.pending: {
            return strings ? strings["enum"]["StatusSeller"]["pending"] || StatusSeller.pending : StatusSeller.pending;
        }
        case StatusSeller.rejected: {
            return strings ? strings["enum"]["StatusSeller"]["rejected"] || StatusSeller.rejected : StatusSeller.rejected;
        }
        case StatusSeller.accepted: {
            return strings ? strings["enum"]["StatusSeller"]["accepted"] || StatusSeller.accepted : StatusSeller.accepted;
        }
    }
    return "";
}

export function allValuesStatusSeller(): StatusSeller[] {
    return [
        StatusSeller.pending,
        StatusSeller.rejected,
        StatusSeller.accepted,
    ];
}

export function allTranslatedValuesStatusSeller(): string[] {
    return [
        translateStatusSeller(StatusSeller.pending),
        translateStatusSeller(StatusSeller.rejected),
        translateStatusSeller(StatusSeller.accepted),
    ];
}

export function allDisplayableValuesStatusSeller(): string[] {
    return allTranslatedValuesStatusSeller().sort();
}

export function valueFromTranslationStatusSeller(translation: string): StatusSeller {
    const index = allTranslatedValuesStatusSeller().indexOf(translation);
    return allValuesStatusSeller()[index] || StatusSeller.pending;
}

export enum TransferHistoryStatus {
    transferred = "transferred",
    processing = "processing",
    requested = "requested",
    canceled = "canceled",
    done = "done",
}

export function translateTransferHistoryStatus(enumTransferHistoryStatus: TransferHistoryStatus): string {
    switch (enumTransferHistoryStatus) {
        case TransferHistoryStatus.transferred: {
            return strings ? strings["enum"]["TransferHistoryStatus"]["transferred"] || TransferHistoryStatus.transferred : TransferHistoryStatus.transferred;
        }
        case TransferHistoryStatus.processing: {
            return strings ? strings["enum"]["TransferHistoryStatus"]["processing"] || TransferHistoryStatus.processing : TransferHistoryStatus.processing;
        }
        case TransferHistoryStatus.requested: {
            return strings ? strings["enum"]["TransferHistoryStatus"]["requested"] || TransferHistoryStatus.requested : TransferHistoryStatus.requested;
        }
        case TransferHistoryStatus.canceled: {
            return strings ? strings["enum"]["TransferHistoryStatus"]["canceled"] || TransferHistoryStatus.canceled : TransferHistoryStatus.canceled;
        }
        case TransferHistoryStatus.done: {
            return strings ? strings["enum"]["TransferHistoryStatus"]["done"] || TransferHistoryStatus.done : TransferHistoryStatus.done;
        }
    }
    return "";
}

export function allValuesTransferHistoryStatus(): TransferHistoryStatus[] {
    return [
        TransferHistoryStatus.transferred,
        TransferHistoryStatus.processing,
        TransferHistoryStatus.requested,
        TransferHistoryStatus.canceled,
        TransferHistoryStatus.done,
    ];
}

export function allTranslatedValuesTransferHistoryStatus(): string[] {
    return [
        translateTransferHistoryStatus(TransferHistoryStatus.transferred),
        translateTransferHistoryStatus(TransferHistoryStatus.processing),
        translateTransferHistoryStatus(TransferHistoryStatus.requested),
        translateTransferHistoryStatus(TransferHistoryStatus.canceled),
        translateTransferHistoryStatus(TransferHistoryStatus.done),
    ];
}

export function allDisplayableValuesTransferHistoryStatus(): string[] {
    return allTranslatedValuesTransferHistoryStatus().sort();
}

export function valueFromTranslationTransferHistoryStatus(translation: string): TransferHistoryStatus {
    const index = allTranslatedValuesTransferHistoryStatus().indexOf(translation);
    return allValuesTransferHistoryStatus()[index] || TransferHistoryStatus.transferred;
}

export enum TicketStatus {
    purchased = "purchased",
    reserved = "reserved",
}

export function translateTicketStatus(enumTicketStatus: TicketStatus): string {
    switch (enumTicketStatus) {
        case TicketStatus.purchased: {
            return strings ? strings["enum"]["TicketStatus"]["purchased"] || TicketStatus.purchased : TicketStatus.purchased;
        }
        case TicketStatus.reserved: {
            return strings ? strings["enum"]["TicketStatus"]["reserved"] || TicketStatus.reserved : TicketStatus.reserved;
        }
    }
    return "";
}

export function allValuesTicketStatus(): TicketStatus[] {
    return [
        TicketStatus.purchased,
        TicketStatus.reserved,
    ];
}

export function allTranslatedValuesTicketStatus(): string[] {
    return [
        translateTicketStatus(TicketStatus.purchased),
        translateTicketStatus(TicketStatus.reserved),
    ];
}

export function allDisplayableValuesTicketStatus(): string[] {
    return allTranslatedValuesTicketStatus().sort();
}

export function valueFromTranslationTicketStatus(translation: string): TicketStatus {
    const index = allTranslatedValuesTicketStatus().indexOf(translation);
    return allValuesTicketStatus()[index] || TicketStatus.purchased;
}

export enum TicketSaleType {
    internal = "internal",
    external = "external",
}

export function translateTicketSaleType(enumTicketSaleType: TicketSaleType): string {
    switch (enumTicketSaleType) {
        case TicketSaleType.internal: {
            return strings ? strings["enum"]["TicketSaleType"]["internal"] || TicketSaleType.internal : TicketSaleType.internal;
        }
        case TicketSaleType.external: {
            return strings ? strings["enum"]["TicketSaleType"]["external"] || TicketSaleType.external : TicketSaleType.external;
        }
    }
    return "";
}

export function allValuesTicketSaleType(): TicketSaleType[] {
    return [
        TicketSaleType.internal,
        TicketSaleType.external,
    ];
}

export function allTranslatedValuesTicketSaleType(): string[] {
    return [
        translateTicketSaleType(TicketSaleType.internal),
        translateTicketSaleType(TicketSaleType.external),
    ];
}

export function allDisplayableValuesTicketSaleType(): string[] {
    return allTranslatedValuesTicketSaleType().sort();
}

export function valueFromTranslationTicketSaleType(translation: string): TicketSaleType {
    const index = allTranslatedValuesTicketSaleType().indexOf(translation);
    return allValuesTicketSaleType()[index] || TicketSaleType.internal;
}

export enum RafflePlanPaymentStatus {
    pendingPayment = "pendingPayment",
    paid = "paid",
    canceled = "canceled",
}

export function translateRafflePlanPaymentStatus(enumRafflePlanPaymentStatus: RafflePlanPaymentStatus): string {
    switch (enumRafflePlanPaymentStatus) {
        case RafflePlanPaymentStatus.pendingPayment: {
            return strings ? strings["enum"]["RafflePlanPaymentStatus"]["pendingPayment"] || RafflePlanPaymentStatus.pendingPayment : RafflePlanPaymentStatus.pendingPayment;
        }
        case RafflePlanPaymentStatus.paid: {
            return strings ? strings["enum"]["RafflePlanPaymentStatus"]["paid"] || RafflePlanPaymentStatus.paid : RafflePlanPaymentStatus.paid;
        }
        case RafflePlanPaymentStatus.canceled: {
            return strings ? strings["enum"]["RafflePlanPaymentStatus"]["canceled"] || RafflePlanPaymentStatus.canceled : RafflePlanPaymentStatus.canceled;
        }
    }
    return "";
}

export function allValuesRafflePlanPaymentStatus(): RafflePlanPaymentStatus[] {
    return [
        RafflePlanPaymentStatus.pendingPayment,
        RafflePlanPaymentStatus.paid,
        RafflePlanPaymentStatus.canceled,
    ];
}

export function allTranslatedValuesRafflePlanPaymentStatus(): string[] {
    return [
        translateRafflePlanPaymentStatus(RafflePlanPaymentStatus.pendingPayment),
        translateRafflePlanPaymentStatus(RafflePlanPaymentStatus.paid),
        translateRafflePlanPaymentStatus(RafflePlanPaymentStatus.canceled),
    ];
}

export function allDisplayableValuesRafflePlanPaymentStatus(): string[] {
    return allTranslatedValuesRafflePlanPaymentStatus().sort();
}

export function valueFromTranslationRafflePlanPaymentStatus(translation: string): RafflePlanPaymentStatus {
    const index = allTranslatedValuesRafflePlanPaymentStatus().indexOf(translation);
    return allValuesRafflePlanPaymentStatus()[index] || RafflePlanPaymentStatus.pendingPayment;
}

export enum TransactionStatus {
    processing = "processing",
    authorized = "authorized",
    paid = "paid",
    refunded = "refunded",
    waitingPayment = "waitingPayment",
    pendingRefund = "pendingRefund",
    refused = "refused",
    chargedback = "chargedback",
}

export function translateTransactionStatus(enumTransactionStatus: TransactionStatus): string {
    switch (enumTransactionStatus) {
        case TransactionStatus.processing: {
            return strings ? strings["enum"]["TransactionStatus"]["processing"] || TransactionStatus.processing : TransactionStatus.processing;
        }
        case TransactionStatus.authorized: {
            return strings ? strings["enum"]["TransactionStatus"]["authorized"] || TransactionStatus.authorized : TransactionStatus.authorized;
        }
        case TransactionStatus.paid: {
            return strings ? strings["enum"]["TransactionStatus"]["paid"] || TransactionStatus.paid : TransactionStatus.paid;
        }
        case TransactionStatus.refunded: {
            return strings ? strings["enum"]["TransactionStatus"]["refunded"] || TransactionStatus.refunded : TransactionStatus.refunded;
        }
        case TransactionStatus.waitingPayment: {
            return strings ? strings["enum"]["TransactionStatus"]["waitingPayment"] || TransactionStatus.waitingPayment : TransactionStatus.waitingPayment;
        }
        case TransactionStatus.pendingRefund: {
            return strings ? strings["enum"]["TransactionStatus"]["pendingRefund"] || TransactionStatus.pendingRefund : TransactionStatus.pendingRefund;
        }
        case TransactionStatus.refused: {
            return strings ? strings["enum"]["TransactionStatus"]["refused"] || TransactionStatus.refused : TransactionStatus.refused;
        }
        case TransactionStatus.chargedback: {
            return strings ? strings["enum"]["TransactionStatus"]["chargedback"] || TransactionStatus.chargedback : TransactionStatus.chargedback;
        }
    }
    return "";
}

export function allValuesTransactionStatus(): TransactionStatus[] {
    return [
        TransactionStatus.processing,
        TransactionStatus.authorized,
        TransactionStatus.paid,
        TransactionStatus.refunded,
        TransactionStatus.waitingPayment,
        TransactionStatus.pendingRefund,
        TransactionStatus.refused,
        TransactionStatus.chargedback,
    ];
}

export function allTranslatedValuesTransactionStatus(): string[] {
    return [
        translateTransactionStatus(TransactionStatus.processing),
        translateTransactionStatus(TransactionStatus.authorized),
        translateTransactionStatus(TransactionStatus.paid),
        translateTransactionStatus(TransactionStatus.refunded),
        translateTransactionStatus(TransactionStatus.waitingPayment),
        translateTransactionStatus(TransactionStatus.pendingRefund),
        translateTransactionStatus(TransactionStatus.refused),
        translateTransactionStatus(TransactionStatus.chargedback),
    ];
}

export function allDisplayableValuesTransactionStatus(): string[] {
    return allTranslatedValuesTransactionStatus().sort();
}

export function valueFromTranslationTransactionStatus(translation: string): TransactionStatus {
    const index = allTranslatedValuesTransactionStatus().indexOf(translation);
    return allValuesTransactionStatus()[index] || TransactionStatus.processing;
}

export enum PaymentMethod {
    creditCard = "creditCard",
    boleto = "boleto",
    PIX = "PIX",
    external = "external",
}

export function translatePaymentMethod(enumPaymentMethod: PaymentMethod): string {
    switch (enumPaymentMethod) {
        case PaymentMethod.creditCard: {
            return strings ? strings["enum"]["PaymentMethod"]["creditCard"] || PaymentMethod.creditCard : PaymentMethod.creditCard;
        }
        case PaymentMethod.boleto: {
            return strings ? strings["enum"]["PaymentMethod"]["boleto"] || PaymentMethod.boleto : PaymentMethod.boleto;
        }
        case PaymentMethod.PIX: {
            return strings ? strings["enum"]["PaymentMethod"]["PIX"] || PaymentMethod.PIX : PaymentMethod.PIX;
        }
        case PaymentMethod.external: {
            return strings ? strings["enum"]["PaymentMethod"]["external"] || PaymentMethod.external : PaymentMethod.external;
        }
    }
    return "";
}

export function allValuesPaymentMethod(): PaymentMethod[] {
    return [
        PaymentMethod.creditCard,
        PaymentMethod.boleto,
        PaymentMethod.PIX,
        PaymentMethod.external,
    ];
}

export function allTranslatedValuesPaymentMethod(): string[] {
    return [
        translatePaymentMethod(PaymentMethod.creditCard),
        translatePaymentMethod(PaymentMethod.boleto),
        translatePaymentMethod(PaymentMethod.PIX),
        translatePaymentMethod(PaymentMethod.external),
    ];
}

export function allDisplayableValuesPaymentMethod(): string[] {
    return allTranslatedValuesPaymentMethod().sort();
}

export function valueFromTranslationPaymentMethod(translation: string): PaymentMethod {
    const index = allTranslatedValuesPaymentMethod().indexOf(translation);
    return allValuesPaymentMethod()[index] || PaymentMethod.creditCard;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    NickAlreadyRegistered = "NickAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    PhonelAlreadyRegistered = "PhonelAlreadyRegistered",
    InvalidEditRequest = "InvalidEditRequest",
    RaffleResultAlreadyRegistered = "RaffleResultAlreadyRegistered",
    InvalidRequestToSeller = "InvalidRequestToSeller",
    TicketUnavailable = "TicketUnavailable",
    NeededDocument = "NeededDocument",
    NeededBirthdate = "NeededBirthdate",
    ExceededTheTicketsLimitPerRaffle = "ExceededTheTicketsLimitPerRaffle",
    PagarMeBankAccount = "PagarMeBankAccount",
    PagarMeCreditCard = "PagarMeCreditCard",
    PagarMeTransaction = "PagarMeTransaction",
    PagarMeNotPaid = "PagarMeNotPaid",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.NickAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyRegistered"] || ErrorType.NickAlreadyRegistered : ErrorType.NickAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.PhonelAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["PhonelAlreadyRegistered"] || ErrorType.PhonelAlreadyRegistered : ErrorType.PhonelAlreadyRegistered;
        }
        case ErrorType.InvalidEditRequest: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEditRequest"] || ErrorType.InvalidEditRequest : ErrorType.InvalidEditRequest;
        }
        case ErrorType.RaffleResultAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["RaffleResultAlreadyRegistered"] || ErrorType.RaffleResultAlreadyRegistered : ErrorType.RaffleResultAlreadyRegistered;
        }
        case ErrorType.InvalidRequestToSeller: {
            return strings ? strings["enum"]["ErrorType"]["InvalidRequestToSeller"] || ErrorType.InvalidRequestToSeller : ErrorType.InvalidRequestToSeller;
        }
        case ErrorType.TicketUnavailable: {
            return strings ? strings["enum"]["ErrorType"]["TicketUnavailable"] || ErrorType.TicketUnavailable : ErrorType.TicketUnavailable;
        }
        case ErrorType.NeededDocument: {
            return strings ? strings["enum"]["ErrorType"]["NeededDocument"] || ErrorType.NeededDocument : ErrorType.NeededDocument;
        }
        case ErrorType.NeededBirthdate: {
            return strings ? strings["enum"]["ErrorType"]["NeededBirthdate"] || ErrorType.NeededBirthdate : ErrorType.NeededBirthdate;
        }
        case ErrorType.ExceededTheTicketsLimitPerRaffle: {
            return strings ? strings["enum"]["ErrorType"]["ExceededTheTicketsLimitPerRaffle"] || ErrorType.ExceededTheTicketsLimitPerRaffle : ErrorType.ExceededTheTicketsLimitPerRaffle;
        }
        case ErrorType.PagarMeBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeBankAccount"] || ErrorType.PagarMeBankAccount : ErrorType.PagarMeBankAccount;
        }
        case ErrorType.PagarMeCreditCard: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeCreditCard"] || ErrorType.PagarMeCreditCard : ErrorType.PagarMeCreditCard;
        }
        case ErrorType.PagarMeTransaction: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeTransaction"] || ErrorType.PagarMeTransaction : ErrorType.PagarMeTransaction;
        }
        case ErrorType.PagarMeNotPaid: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeNotPaid"] || ErrorType.PagarMeNotPaid : ErrorType.PagarMeNotPaid;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.NickAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.PhonelAlreadyRegistered,
        ErrorType.InvalidEditRequest,
        ErrorType.RaffleResultAlreadyRegistered,
        ErrorType.InvalidRequestToSeller,
        ErrorType.TicketUnavailable,
        ErrorType.NeededDocument,
        ErrorType.NeededBirthdate,
        ErrorType.ExceededTheTicketsLimitPerRaffle,
        ErrorType.PagarMeBankAccount,
        ErrorType.PagarMeCreditCard,
        ErrorType.PagarMeTransaction,
        ErrorType.PagarMeNotPaid,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.NickAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.PhonelAlreadyRegistered),
        translateErrorType(ErrorType.InvalidEditRequest),
        translateErrorType(ErrorType.RaffleResultAlreadyRegistered),
        translateErrorType(ErrorType.InvalidRequestToSeller),
        translateErrorType(ErrorType.TicketUnavailable),
        translateErrorType(ErrorType.NeededDocument),
        translateErrorType(ErrorType.NeededBirthdate),
        translateErrorType(ErrorType.ExceededTheTicketsLimitPerRaffle),
        translateErrorType(ErrorType.PagarMeBankAccount),
        translateErrorType(ErrorType.PagarMeCreditCard),
        translateErrorType(ErrorType.PagarMeTransaction),
        translateErrorType(ErrorType.PagarMeNotPaid),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getFAQById(id: string, progress?: (progress: number) => void): Promise<FAQ> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getFAQById", args, progress});
    return {
        id: ret.id,
        question: ret.question,
        answer: ret.answer,
    };
}

export async function getAllFAQ(pageOffset: number, progress?: (progress: number) => void): Promise<FAQ[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllFAQ", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        question: e.question,
        answer: e.answer,
    }));
}

export async function getAllFAQWithOutPaginated(progress?: (progress: number) => void): Promise<FAQ[]> {
    const ret = await makeRequest({name: "getAllFAQWithOutPaginated", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        question: e.question,
        answer: e.answer,
    }));
}

export async function createFAQ(newFAQ: NewFAQ, progress?: (progress: number) => void): Promise<FAQ> {
    const args = {
        newFAQ: {
            answer: newFAQ.answer,
            question: newFAQ.question,
        },
    };
    const ret = await makeRequest({name: "createFAQ", args, progress});
    return {
        id: ret.id,
        question: ret.question,
        answer: ret.answer,
    };
}

export async function editFAQ(id: string, newFAQ: NewFAQ, progress?: (progress: number) => void): Promise<FAQ> {
    const args = {
        id: id,
        newFAQ: {
            answer: newFAQ.answer,
            question: newFAQ.question,
        },
    };
    const ret = await makeRequest({name: "editFAQ", args, progress});
    return {
        id: ret.id,
        question: ret.question,
        answer: ret.answer,
    };
}

export async function deleteFAQ(id: string, progress?: (progress: number) => void): Promise<FAQ> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteFAQ", args, progress});
    return {
        id: ret.id,
        question: ret.question,
        answer: ret.answer,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getBannerById(id: string, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getBannerById", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        message: ret.message,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        contentImage: ret.contentImage === null || ret.contentImage === undefined ? null : {
            thumb: {
                width: ret.contentImage.thumb.width || 0,
                height: ret.contentImage.thumb.height || 0,
                url: ret.contentImage.thumb.url,
            },
            width: ret.contentImage.width || 0,
            height: ret.contentImage.height || 0,
            url: ret.contentImage.url,
        },
    };
}

export async function getAllBanners(pageOffset: number, progress?: (progress: number) => void): Promise<Banner[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllBanners", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        message: e.message,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        contentImage: e.contentImage === null || e.contentImage === undefined ? null : {
            thumb: {
                width: e.contentImage.thumb.width || 0,
                height: e.contentImage.thumb.height || 0,
                url: e.contentImage.thumb.url,
            },
            width: e.contentImage.width || 0,
            height: e.contentImage.height || 0,
            url: e.contentImage.url,
        },
    }));
}

export async function createBanner(newBanner: NewBanner, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        newBanner: {
            title: newBanner.title,
            message: newBanner.message,
            image: {
                bytes: newBanner.image.bytes === null || newBanner.image.bytes === undefined ? null : newBanner.image.bytes.toString("base64"),
                image: newBanner.image.image === null || newBanner.image.image === undefined ? null : {
                    thumb: {
                        width: newBanner.image.image.thumb.width || 0,
                        height: newBanner.image.image.thumb.height || 0,
                        url: newBanner.image.image.thumb.url,
                    },
                    width: newBanner.image.image.width || 0,
                    height: newBanner.image.image.height || 0,
                    url: newBanner.image.image.url,
                },
            },
            contentImage: newBanner.contentImage === null || newBanner.contentImage === undefined ? null : {
                bytes: newBanner.contentImage.bytes === null || newBanner.contentImage.bytes === undefined ? null : newBanner.contentImage.bytes.toString("base64"),
                image: newBanner.contentImage.image === null || newBanner.contentImage.image === undefined ? null : {
                    thumb: {
                        width: newBanner.contentImage.image.thumb.width || 0,
                        height: newBanner.contentImage.image.thumb.height || 0,
                        url: newBanner.contentImage.image.thumb.url,
                    },
                    width: newBanner.contentImage.image.width || 0,
                    height: newBanner.contentImage.image.height || 0,
                    url: newBanner.contentImage.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "createBanner", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        message: ret.message,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        contentImage: ret.contentImage === null || ret.contentImage === undefined ? null : {
            thumb: {
                width: ret.contentImage.thumb.width || 0,
                height: ret.contentImage.thumb.height || 0,
                url: ret.contentImage.thumb.url,
            },
            width: ret.contentImage.width || 0,
            height: ret.contentImage.height || 0,
            url: ret.contentImage.url,
        },
    };
}

export async function editBanner(id: string, newBanner: NewBanner, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        id: id,
        newBanner: {
            title: newBanner.title,
            message: newBanner.message,
            image: {
                bytes: newBanner.image.bytes === null || newBanner.image.bytes === undefined ? null : newBanner.image.bytes.toString("base64"),
                image: newBanner.image.image === null || newBanner.image.image === undefined ? null : {
                    thumb: {
                        width: newBanner.image.image.thumb.width || 0,
                        height: newBanner.image.image.thumb.height || 0,
                        url: newBanner.image.image.thumb.url,
                    },
                    width: newBanner.image.image.width || 0,
                    height: newBanner.image.image.height || 0,
                    url: newBanner.image.image.url,
                },
            },
            contentImage: newBanner.contentImage === null || newBanner.contentImage === undefined ? null : {
                bytes: newBanner.contentImage.bytes === null || newBanner.contentImage.bytes === undefined ? null : newBanner.contentImage.bytes.toString("base64"),
                image: newBanner.contentImage.image === null || newBanner.contentImage.image === undefined ? null : {
                    thumb: {
                        width: newBanner.contentImage.image.thumb.width || 0,
                        height: newBanner.contentImage.image.thumb.height || 0,
                        url: newBanner.contentImage.image.thumb.url,
                    },
                    width: newBanner.contentImage.image.width || 0,
                    height: newBanner.contentImage.image.height || 0,
                    url: newBanner.contentImage.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editBanner", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        message: ret.message,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        contentImage: ret.contentImage === null || ret.contentImage === undefined ? null : {
            thumb: {
                width: ret.contentImage.thumb.width || 0,
                height: ret.contentImage.thumb.height || 0,
                url: ret.contentImage.thumb.url,
            },
            width: ret.contentImage.width || 0,
            height: ret.contentImage.height || 0,
            url: ret.contentImage.url,
        },
    };
}

export async function deleteBanner(id: string, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteBanner", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        message: ret.message,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        contentImage: ret.contentImage === null || ret.contentImage === undefined ? null : {
            thumb: {
                width: ret.contentImage.thumb.width || 0,
                height: ret.contentImage.thumb.height || 0,
                url: ret.contentImage.thumb.url,
            },
            width: ret.contentImage.width || 0,
            height: ret.contentImage.height || 0,
            url: ret.contentImage.url,
        },
    };
}

export async function getDrawTickets(raffleId: string, progress?: (progress: number) => void): Promise<DrawnTicket[] | null> {
    const args = {
        raffleId: raffleId,
    };
    const ret = await makeRequest({name: "getDrawTickets", args, progress});
    return ret === null || ret === undefined ? null : ret.map((e: any) => ({
        id: e.id,
        prizeNumber: e.prizeNumber || 0,
        forcedResult: !!e.forcedResult,
        raffleTicket: {
            id: e.raffleTicket.id,
            number: e.raffleTicket.number || 0,
            status: e.raffleTicket.status,
            saleType: e.raffleTicket.saleType,
            owner: {
                id: e.raffleTicket.owner.id === null || e.raffleTicket.owner.id === undefined ? null : e.raffleTicket.owner.id,
                image: e.raffleTicket.owner.image === null || e.raffleTicket.owner.image === undefined ? null : {
                    thumb: {
                        width: e.raffleTicket.owner.image.thumb.width || 0,
                        height: e.raffleTicket.owner.image.thumb.height || 0,
                        url: e.raffleTicket.owner.image.thumb.url,
                    },
                    width: e.raffleTicket.owner.image.width || 0,
                    height: e.raffleTicket.owner.image.height || 0,
                    url: e.raffleTicket.owner.image.url,
                },
                name: e.raffleTicket.owner.name,
                phone: e.raffleTicket.owner.phone,
                email: e.raffleTicket.owner.email,
            },
        },
        confirmed: !!e.confirmed,
        raffle: {
            id: e.raffle.id,
            owner: {
                id: e.raffle.owner.id,
                email: e.raffle.owner.email,
                name: e.raffle.owner.name,
                image: e.raffle.owner.image === null || e.raffle.owner.image === undefined ? null : {
                    thumb: {
                        width: e.raffle.owner.image.thumb.width || 0,
                        height: e.raffle.owner.image.thumb.height || 0,
                        url: e.raffle.owner.image.thumb.url,
                    },
                    width: e.raffle.owner.image.width || 0,
                    height: e.raffle.owner.image.height || 0,
                    url: e.raffle.owner.image.url,
                },
                phone: e.raffle.owner.phone,
                cpf: e.raffle.owner.cpf === null || e.raffle.owner.cpf === undefined ? null : e.raffle.owner.cpf,
                birthdate: e.raffle.owner.birthdate === null || e.raffle.owner.birthdate === undefined ? null : new Date(parseInt(e.raffle.owner.birthdate.split("-")[0], 10), parseInt(e.raffle.owner.birthdate.split("-")[1], 10) - 1, parseInt(e.raffle.owner.birthdate.split("-")[2], 10)),
                seller: e.raffle.owner.seller === null || e.raffle.owner.seller === undefined ? null : {
                    documentFrontImage: {
                        thumb: {
                            width: e.raffle.owner.seller.documentFrontImage.thumb.width || 0,
                            height: e.raffle.owner.seller.documentFrontImage.thumb.height || 0,
                            url: e.raffle.owner.seller.documentFrontImage.thumb.url,
                        },
                        width: e.raffle.owner.seller.documentFrontImage.width || 0,
                        height: e.raffle.owner.seller.documentFrontImage.height || 0,
                        url: e.raffle.owner.seller.documentFrontImage.url,
                    },
                    documentBackImage: {
                        thumb: {
                            width: e.raffle.owner.seller.documentBackImage.thumb.width || 0,
                            height: e.raffle.owner.seller.documentBackImage.thumb.height || 0,
                            url: e.raffle.owner.seller.documentBackImage.thumb.url,
                        },
                        width: e.raffle.owner.seller.documentBackImage.width || 0,
                        height: e.raffle.owner.seller.documentBackImage.height || 0,
                        url: e.raffle.owner.seller.documentBackImage.url,
                    },
                    photoHoldingDocument: {
                        thumb: {
                            width: e.raffle.owner.seller.photoHoldingDocument.thumb.width || 0,
                            height: e.raffle.owner.seller.photoHoldingDocument.thumb.height || 0,
                            url: e.raffle.owner.seller.photoHoldingDocument.thumb.url,
                        },
                        width: e.raffle.owner.seller.photoHoldingDocument.width || 0,
                        height: e.raffle.owner.seller.photoHoldingDocument.height || 0,
                        url: e.raffle.owner.seller.photoHoldingDocument.url,
                    },
                    approved: e.raffle.owner.seller.approved,
                    bankAccount: e.raffle.owner.seller.bankAccount === null || e.raffle.owner.seller.bankAccount === undefined ? null : {
                        id: e.raffle.owner.seller.bankAccount.id,
                        bankCode: e.raffle.owner.seller.bankAccount.bankCode,
                        agency: e.raffle.owner.seller.bankAccount.agency,
                        agencyDv: e.raffle.owner.seller.bankAccount.agencyDv === null || e.raffle.owner.seller.bankAccount.agencyDv === undefined ? null : e.raffle.owner.seller.bankAccount.agencyDv,
                        account: e.raffle.owner.seller.bankAccount.account,
                        accountDv: e.raffle.owner.seller.bankAccount.accountDv === null || e.raffle.owner.seller.bankAccount.accountDv === undefined ? null : e.raffle.owner.seller.bankAccount.accountDv,
                        type: e.raffle.owner.seller.bankAccount.type,
                    },
                },
                address: e.raffle.owner.address === null || e.raffle.owner.address === undefined ? null : {
                    zipcode: e.raffle.owner.address.zipcode,
                    street: e.raffle.owner.address.street,
                    streetNumber: e.raffle.owner.address.streetNumber,
                    complementary: e.raffle.owner.address.complementary === null || e.raffle.owner.address.complementary === undefined ? null : e.raffle.owner.address.complementary,
                    neighborhood: e.raffle.owner.address.neighborhood,
                    city: e.raffle.owner.address.city,
                    state: e.raffle.owner.address.state,
                    countryCode: e.raffle.owner.address.countryCode,
                    latLng: e.raffle.owner.address.latLng === null || e.raffle.owner.address.latLng === undefined ? null : {
                        lat: e.raffle.owner.address.latLng.lat,
                        lng: e.raffle.owner.address.latLng.lng,
                    },
                },
                createdAt: new Date(e.raffle.owner.createdAt + "Z"),
            },
            plan: {
                id: e.raffle.plan.id,
                title: e.raffle.plan.title,
                limit: e.raffle.plan.limit || 0,
                price: e.raffle.plan.price || 0,
            },
            title: e.raffle.title,
            objective: e.raffle.objective,
            images: e.raffle.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            ticketPrice: e.raffle.ticketPrice || 0,
            endDate: new Date(parseInt(e.raffle.endDate.split("-")[0], 10), parseInt(e.raffle.endDate.split("-")[1], 10) - 1, parseInt(e.raffle.endDate.split("-")[2], 10)),
            goal: e.raffle.goal || 0,
            amountOfPrizes: e.raffle.amountOfPrizes || 0,
            prizes: e.raffle.prizes.map((e: any) => e),
            forceResult: !!e.raffle.forceResult,
            finished: !!e.raffle.finished,
            ticketsSold: e.raffle.ticketsSold || 0,
            transferStatus: e.raffle.transferStatus,
            planPaymentStatus: e.raffle.planPaymentStatus,
            createdAt: new Date(parseInt(e.raffle.createdAt.split("-")[0], 10), parseInt(e.raffle.createdAt.split("-")[1], 10) - 1, parseInt(e.raffle.createdAt.split("-")[2], 10)),
        },
    }));
}

export async function getTicket(id: string, progress?: (progress: number) => void): Promise<Ticket> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getTicket", args, progress});
    return {
        id: ret.id,
        number: ret.number || 0,
        status: ret.status,
        saleType: ret.saleType,
        owner: {
            id: ret.owner.id === null || ret.owner.id === undefined ? null : ret.owner.id,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            name: ret.owner.name,
            phone: ret.owner.phone,
            email: ret.owner.email,
        },
    };
}

export async function getRaffleTickets(id: string, pageOffset: number, progress?: (progress: number) => void): Promise<Ticket[]> {
    const args = {
        id: id,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getRaffleTickets", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        number: e.number || 0,
        status: e.status,
        saleType: e.saleType,
        owner: {
            id: e.owner.id === null || e.owner.id === undefined ? null : e.owner.id,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            name: e.owner.name,
            phone: e.owner.phone,
            email: e.owner.email,
        },
    }));
}

export async function getDrawnTicket(id: string, progress?: (progress: number) => void): Promise<DrawnTicket> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getDrawnTicket", args, progress});
    return {
        id: ret.id,
        prizeNumber: ret.prizeNumber || 0,
        forcedResult: !!ret.forcedResult,
        raffleTicket: {
            id: ret.raffleTicket.id,
            number: ret.raffleTicket.number || 0,
            status: ret.raffleTicket.status,
            saleType: ret.raffleTicket.saleType,
            owner: {
                id: ret.raffleTicket.owner.id === null || ret.raffleTicket.owner.id === undefined ? null : ret.raffleTicket.owner.id,
                image: ret.raffleTicket.owner.image === null || ret.raffleTicket.owner.image === undefined ? null : {
                    thumb: {
                        width: ret.raffleTicket.owner.image.thumb.width || 0,
                        height: ret.raffleTicket.owner.image.thumb.height || 0,
                        url: ret.raffleTicket.owner.image.thumb.url,
                    },
                    width: ret.raffleTicket.owner.image.width || 0,
                    height: ret.raffleTicket.owner.image.height || 0,
                    url: ret.raffleTicket.owner.image.url,
                },
                name: ret.raffleTicket.owner.name,
                phone: ret.raffleTicket.owner.phone,
                email: ret.raffleTicket.owner.email,
            },
        },
        confirmed: !!ret.confirmed,
        raffle: {
            id: ret.raffle.id,
            owner: {
                id: ret.raffle.owner.id,
                email: ret.raffle.owner.email,
                name: ret.raffle.owner.name,
                image: ret.raffle.owner.image === null || ret.raffle.owner.image === undefined ? null : {
                    thumb: {
                        width: ret.raffle.owner.image.thumb.width || 0,
                        height: ret.raffle.owner.image.thumb.height || 0,
                        url: ret.raffle.owner.image.thumb.url,
                    },
                    width: ret.raffle.owner.image.width || 0,
                    height: ret.raffle.owner.image.height || 0,
                    url: ret.raffle.owner.image.url,
                },
                phone: ret.raffle.owner.phone,
                cpf: ret.raffle.owner.cpf === null || ret.raffle.owner.cpf === undefined ? null : ret.raffle.owner.cpf,
                birthdate: ret.raffle.owner.birthdate === null || ret.raffle.owner.birthdate === undefined ? null : new Date(parseInt(ret.raffle.owner.birthdate.split("-")[0], 10), parseInt(ret.raffle.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.raffle.owner.birthdate.split("-")[2], 10)),
                seller: ret.raffle.owner.seller === null || ret.raffle.owner.seller === undefined ? null : {
                    documentFrontImage: {
                        thumb: {
                            width: ret.raffle.owner.seller.documentFrontImage.thumb.width || 0,
                            height: ret.raffle.owner.seller.documentFrontImage.thumb.height || 0,
                            url: ret.raffle.owner.seller.documentFrontImage.thumb.url,
                        },
                        width: ret.raffle.owner.seller.documentFrontImage.width || 0,
                        height: ret.raffle.owner.seller.documentFrontImage.height || 0,
                        url: ret.raffle.owner.seller.documentFrontImage.url,
                    },
                    documentBackImage: {
                        thumb: {
                            width: ret.raffle.owner.seller.documentBackImage.thumb.width || 0,
                            height: ret.raffle.owner.seller.documentBackImage.thumb.height || 0,
                            url: ret.raffle.owner.seller.documentBackImage.thumb.url,
                        },
                        width: ret.raffle.owner.seller.documentBackImage.width || 0,
                        height: ret.raffle.owner.seller.documentBackImage.height || 0,
                        url: ret.raffle.owner.seller.documentBackImage.url,
                    },
                    photoHoldingDocument: {
                        thumb: {
                            width: ret.raffle.owner.seller.photoHoldingDocument.thumb.width || 0,
                            height: ret.raffle.owner.seller.photoHoldingDocument.thumb.height || 0,
                            url: ret.raffle.owner.seller.photoHoldingDocument.thumb.url,
                        },
                        width: ret.raffle.owner.seller.photoHoldingDocument.width || 0,
                        height: ret.raffle.owner.seller.photoHoldingDocument.height || 0,
                        url: ret.raffle.owner.seller.photoHoldingDocument.url,
                    },
                    approved: ret.raffle.owner.seller.approved,
                    bankAccount: ret.raffle.owner.seller.bankAccount === null || ret.raffle.owner.seller.bankAccount === undefined ? null : {
                        id: ret.raffle.owner.seller.bankAccount.id,
                        bankCode: ret.raffle.owner.seller.bankAccount.bankCode,
                        agency: ret.raffle.owner.seller.bankAccount.agency,
                        agencyDv: ret.raffle.owner.seller.bankAccount.agencyDv === null || ret.raffle.owner.seller.bankAccount.agencyDv === undefined ? null : ret.raffle.owner.seller.bankAccount.agencyDv,
                        account: ret.raffle.owner.seller.bankAccount.account,
                        accountDv: ret.raffle.owner.seller.bankAccount.accountDv === null || ret.raffle.owner.seller.bankAccount.accountDv === undefined ? null : ret.raffle.owner.seller.bankAccount.accountDv,
                        type: ret.raffle.owner.seller.bankAccount.type,
                    },
                },
                address: ret.raffle.owner.address === null || ret.raffle.owner.address === undefined ? null : {
                    zipcode: ret.raffle.owner.address.zipcode,
                    street: ret.raffle.owner.address.street,
                    streetNumber: ret.raffle.owner.address.streetNumber,
                    complementary: ret.raffle.owner.address.complementary === null || ret.raffle.owner.address.complementary === undefined ? null : ret.raffle.owner.address.complementary,
                    neighborhood: ret.raffle.owner.address.neighborhood,
                    city: ret.raffle.owner.address.city,
                    state: ret.raffle.owner.address.state,
                    countryCode: ret.raffle.owner.address.countryCode,
                    latLng: ret.raffle.owner.address.latLng === null || ret.raffle.owner.address.latLng === undefined ? null : {
                        lat: ret.raffle.owner.address.latLng.lat,
                        lng: ret.raffle.owner.address.latLng.lng,
                    },
                },
                createdAt: new Date(ret.raffle.owner.createdAt + "Z"),
            },
            plan: {
                id: ret.raffle.plan.id,
                title: ret.raffle.plan.title,
                limit: ret.raffle.plan.limit || 0,
                price: ret.raffle.plan.price || 0,
            },
            title: ret.raffle.title,
            objective: ret.raffle.objective,
            images: ret.raffle.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            ticketPrice: ret.raffle.ticketPrice || 0,
            endDate: new Date(parseInt(ret.raffle.endDate.split("-")[0], 10), parseInt(ret.raffle.endDate.split("-")[1], 10) - 1, parseInt(ret.raffle.endDate.split("-")[2], 10)),
            goal: ret.raffle.goal || 0,
            amountOfPrizes: ret.raffle.amountOfPrizes || 0,
            prizes: ret.raffle.prizes.map((e: any) => e),
            forceResult: !!ret.raffle.forceResult,
            finished: !!ret.raffle.finished,
            ticketsSold: ret.raffle.ticketsSold || 0,
            transferStatus: ret.raffle.transferStatus,
            planPaymentStatus: ret.raffle.planPaymentStatus,
            createdAt: new Date(parseInt(ret.raffle.createdAt.split("-")[0], 10), parseInt(ret.raffle.createdAt.split("-")[1], 10) - 1, parseInt(ret.raffle.createdAt.split("-")[2], 10)),
        },
    };
}

export async function getPrizeConfirmation(id: string, progress?: (progress: number) => void): Promise<DrawnTicket> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPrizeConfirmation", args, progress});
    return {
        id: ret.id,
        prizeNumber: ret.prizeNumber || 0,
        forcedResult: !!ret.forcedResult,
        raffleTicket: {
            id: ret.raffleTicket.id,
            number: ret.raffleTicket.number || 0,
            status: ret.raffleTicket.status,
            saleType: ret.raffleTicket.saleType,
            owner: {
                id: ret.raffleTicket.owner.id === null || ret.raffleTicket.owner.id === undefined ? null : ret.raffleTicket.owner.id,
                image: ret.raffleTicket.owner.image === null || ret.raffleTicket.owner.image === undefined ? null : {
                    thumb: {
                        width: ret.raffleTicket.owner.image.thumb.width || 0,
                        height: ret.raffleTicket.owner.image.thumb.height || 0,
                        url: ret.raffleTicket.owner.image.thumb.url,
                    },
                    width: ret.raffleTicket.owner.image.width || 0,
                    height: ret.raffleTicket.owner.image.height || 0,
                    url: ret.raffleTicket.owner.image.url,
                },
                name: ret.raffleTicket.owner.name,
                phone: ret.raffleTicket.owner.phone,
                email: ret.raffleTicket.owner.email,
            },
        },
        confirmed: !!ret.confirmed,
        raffle: {
            id: ret.raffle.id,
            owner: {
                id: ret.raffle.owner.id,
                email: ret.raffle.owner.email,
                name: ret.raffle.owner.name,
                image: ret.raffle.owner.image === null || ret.raffle.owner.image === undefined ? null : {
                    thumb: {
                        width: ret.raffle.owner.image.thumb.width || 0,
                        height: ret.raffle.owner.image.thumb.height || 0,
                        url: ret.raffle.owner.image.thumb.url,
                    },
                    width: ret.raffle.owner.image.width || 0,
                    height: ret.raffle.owner.image.height || 0,
                    url: ret.raffle.owner.image.url,
                },
                phone: ret.raffle.owner.phone,
                cpf: ret.raffle.owner.cpf === null || ret.raffle.owner.cpf === undefined ? null : ret.raffle.owner.cpf,
                birthdate: ret.raffle.owner.birthdate === null || ret.raffle.owner.birthdate === undefined ? null : new Date(parseInt(ret.raffle.owner.birthdate.split("-")[0], 10), parseInt(ret.raffle.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.raffle.owner.birthdate.split("-")[2], 10)),
                seller: ret.raffle.owner.seller === null || ret.raffle.owner.seller === undefined ? null : {
                    documentFrontImage: {
                        thumb: {
                            width: ret.raffle.owner.seller.documentFrontImage.thumb.width || 0,
                            height: ret.raffle.owner.seller.documentFrontImage.thumb.height || 0,
                            url: ret.raffle.owner.seller.documentFrontImage.thumb.url,
                        },
                        width: ret.raffle.owner.seller.documentFrontImage.width || 0,
                        height: ret.raffle.owner.seller.documentFrontImage.height || 0,
                        url: ret.raffle.owner.seller.documentFrontImage.url,
                    },
                    documentBackImage: {
                        thumb: {
                            width: ret.raffle.owner.seller.documentBackImage.thumb.width || 0,
                            height: ret.raffle.owner.seller.documentBackImage.thumb.height || 0,
                            url: ret.raffle.owner.seller.documentBackImage.thumb.url,
                        },
                        width: ret.raffle.owner.seller.documentBackImage.width || 0,
                        height: ret.raffle.owner.seller.documentBackImage.height || 0,
                        url: ret.raffle.owner.seller.documentBackImage.url,
                    },
                    photoHoldingDocument: {
                        thumb: {
                            width: ret.raffle.owner.seller.photoHoldingDocument.thumb.width || 0,
                            height: ret.raffle.owner.seller.photoHoldingDocument.thumb.height || 0,
                            url: ret.raffle.owner.seller.photoHoldingDocument.thumb.url,
                        },
                        width: ret.raffle.owner.seller.photoHoldingDocument.width || 0,
                        height: ret.raffle.owner.seller.photoHoldingDocument.height || 0,
                        url: ret.raffle.owner.seller.photoHoldingDocument.url,
                    },
                    approved: ret.raffle.owner.seller.approved,
                    bankAccount: ret.raffle.owner.seller.bankAccount === null || ret.raffle.owner.seller.bankAccount === undefined ? null : {
                        id: ret.raffle.owner.seller.bankAccount.id,
                        bankCode: ret.raffle.owner.seller.bankAccount.bankCode,
                        agency: ret.raffle.owner.seller.bankAccount.agency,
                        agencyDv: ret.raffle.owner.seller.bankAccount.agencyDv === null || ret.raffle.owner.seller.bankAccount.agencyDv === undefined ? null : ret.raffle.owner.seller.bankAccount.agencyDv,
                        account: ret.raffle.owner.seller.bankAccount.account,
                        accountDv: ret.raffle.owner.seller.bankAccount.accountDv === null || ret.raffle.owner.seller.bankAccount.accountDv === undefined ? null : ret.raffle.owner.seller.bankAccount.accountDv,
                        type: ret.raffle.owner.seller.bankAccount.type,
                    },
                },
                address: ret.raffle.owner.address === null || ret.raffle.owner.address === undefined ? null : {
                    zipcode: ret.raffle.owner.address.zipcode,
                    street: ret.raffle.owner.address.street,
                    streetNumber: ret.raffle.owner.address.streetNumber,
                    complementary: ret.raffle.owner.address.complementary === null || ret.raffle.owner.address.complementary === undefined ? null : ret.raffle.owner.address.complementary,
                    neighborhood: ret.raffle.owner.address.neighborhood,
                    city: ret.raffle.owner.address.city,
                    state: ret.raffle.owner.address.state,
                    countryCode: ret.raffle.owner.address.countryCode,
                    latLng: ret.raffle.owner.address.latLng === null || ret.raffle.owner.address.latLng === undefined ? null : {
                        lat: ret.raffle.owner.address.latLng.lat,
                        lng: ret.raffle.owner.address.latLng.lng,
                    },
                },
                createdAt: new Date(ret.raffle.owner.createdAt + "Z"),
            },
            plan: {
                id: ret.raffle.plan.id,
                title: ret.raffle.plan.title,
                limit: ret.raffle.plan.limit || 0,
                price: ret.raffle.plan.price || 0,
            },
            title: ret.raffle.title,
            objective: ret.raffle.objective,
            images: ret.raffle.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            ticketPrice: ret.raffle.ticketPrice || 0,
            endDate: new Date(parseInt(ret.raffle.endDate.split("-")[0], 10), parseInt(ret.raffle.endDate.split("-")[1], 10) - 1, parseInt(ret.raffle.endDate.split("-")[2], 10)),
            goal: ret.raffle.goal || 0,
            amountOfPrizes: ret.raffle.amountOfPrizes || 0,
            prizes: ret.raffle.prizes.map((e: any) => e),
            forceResult: !!ret.raffle.forceResult,
            finished: !!ret.raffle.finished,
            ticketsSold: ret.raffle.ticketsSold || 0,
            transferStatus: ret.raffle.transferStatus,
            planPaymentStatus: ret.raffle.planPaymentStatus,
            createdAt: new Date(parseInt(ret.raffle.createdAt.split("-")[0], 10), parseInt(ret.raffle.createdAt.split("-")[1], 10) - 1, parseInt(ret.raffle.createdAt.split("-")[2], 10)),
        },
    };
}

export async function getUnreceivedPrizes(pageOffset: number, progress?: (progress: number) => void): Promise<DrawnTicket[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getUnreceivedPrizes", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        prizeNumber: e.prizeNumber || 0,
        forcedResult: !!e.forcedResult,
        raffleTicket: {
            id: e.raffleTicket.id,
            number: e.raffleTicket.number || 0,
            status: e.raffleTicket.status,
            saleType: e.raffleTicket.saleType,
            owner: {
                id: e.raffleTicket.owner.id === null || e.raffleTicket.owner.id === undefined ? null : e.raffleTicket.owner.id,
                image: e.raffleTicket.owner.image === null || e.raffleTicket.owner.image === undefined ? null : {
                    thumb: {
                        width: e.raffleTicket.owner.image.thumb.width || 0,
                        height: e.raffleTicket.owner.image.thumb.height || 0,
                        url: e.raffleTicket.owner.image.thumb.url,
                    },
                    width: e.raffleTicket.owner.image.width || 0,
                    height: e.raffleTicket.owner.image.height || 0,
                    url: e.raffleTicket.owner.image.url,
                },
                name: e.raffleTicket.owner.name,
                phone: e.raffleTicket.owner.phone,
                email: e.raffleTicket.owner.email,
            },
        },
        confirmed: !!e.confirmed,
        raffle: {
            id: e.raffle.id,
            owner: {
                id: e.raffle.owner.id,
                email: e.raffle.owner.email,
                name: e.raffle.owner.name,
                image: e.raffle.owner.image === null || e.raffle.owner.image === undefined ? null : {
                    thumb: {
                        width: e.raffle.owner.image.thumb.width || 0,
                        height: e.raffle.owner.image.thumb.height || 0,
                        url: e.raffle.owner.image.thumb.url,
                    },
                    width: e.raffle.owner.image.width || 0,
                    height: e.raffle.owner.image.height || 0,
                    url: e.raffle.owner.image.url,
                },
                phone: e.raffle.owner.phone,
                cpf: e.raffle.owner.cpf === null || e.raffle.owner.cpf === undefined ? null : e.raffle.owner.cpf,
                birthdate: e.raffle.owner.birthdate === null || e.raffle.owner.birthdate === undefined ? null : new Date(parseInt(e.raffle.owner.birthdate.split("-")[0], 10), parseInt(e.raffle.owner.birthdate.split("-")[1], 10) - 1, parseInt(e.raffle.owner.birthdate.split("-")[2], 10)),
                seller: e.raffle.owner.seller === null || e.raffle.owner.seller === undefined ? null : {
                    documentFrontImage: {
                        thumb: {
                            width: e.raffle.owner.seller.documentFrontImage.thumb.width || 0,
                            height: e.raffle.owner.seller.documentFrontImage.thumb.height || 0,
                            url: e.raffle.owner.seller.documentFrontImage.thumb.url,
                        },
                        width: e.raffle.owner.seller.documentFrontImage.width || 0,
                        height: e.raffle.owner.seller.documentFrontImage.height || 0,
                        url: e.raffle.owner.seller.documentFrontImage.url,
                    },
                    documentBackImage: {
                        thumb: {
                            width: e.raffle.owner.seller.documentBackImage.thumb.width || 0,
                            height: e.raffle.owner.seller.documentBackImage.thumb.height || 0,
                            url: e.raffle.owner.seller.documentBackImage.thumb.url,
                        },
                        width: e.raffle.owner.seller.documentBackImage.width || 0,
                        height: e.raffle.owner.seller.documentBackImage.height || 0,
                        url: e.raffle.owner.seller.documentBackImage.url,
                    },
                    photoHoldingDocument: {
                        thumb: {
                            width: e.raffle.owner.seller.photoHoldingDocument.thumb.width || 0,
                            height: e.raffle.owner.seller.photoHoldingDocument.thumb.height || 0,
                            url: e.raffle.owner.seller.photoHoldingDocument.thumb.url,
                        },
                        width: e.raffle.owner.seller.photoHoldingDocument.width || 0,
                        height: e.raffle.owner.seller.photoHoldingDocument.height || 0,
                        url: e.raffle.owner.seller.photoHoldingDocument.url,
                    },
                    approved: e.raffle.owner.seller.approved,
                    bankAccount: e.raffle.owner.seller.bankAccount === null || e.raffle.owner.seller.bankAccount === undefined ? null : {
                        id: e.raffle.owner.seller.bankAccount.id,
                        bankCode: e.raffle.owner.seller.bankAccount.bankCode,
                        agency: e.raffle.owner.seller.bankAccount.agency,
                        agencyDv: e.raffle.owner.seller.bankAccount.agencyDv === null || e.raffle.owner.seller.bankAccount.agencyDv === undefined ? null : e.raffle.owner.seller.bankAccount.agencyDv,
                        account: e.raffle.owner.seller.bankAccount.account,
                        accountDv: e.raffle.owner.seller.bankAccount.accountDv === null || e.raffle.owner.seller.bankAccount.accountDv === undefined ? null : e.raffle.owner.seller.bankAccount.accountDv,
                        type: e.raffle.owner.seller.bankAccount.type,
                    },
                },
                address: e.raffle.owner.address === null || e.raffle.owner.address === undefined ? null : {
                    zipcode: e.raffle.owner.address.zipcode,
                    street: e.raffle.owner.address.street,
                    streetNumber: e.raffle.owner.address.streetNumber,
                    complementary: e.raffle.owner.address.complementary === null || e.raffle.owner.address.complementary === undefined ? null : e.raffle.owner.address.complementary,
                    neighborhood: e.raffle.owner.address.neighborhood,
                    city: e.raffle.owner.address.city,
                    state: e.raffle.owner.address.state,
                    countryCode: e.raffle.owner.address.countryCode,
                    latLng: e.raffle.owner.address.latLng === null || e.raffle.owner.address.latLng === undefined ? null : {
                        lat: e.raffle.owner.address.latLng.lat,
                        lng: e.raffle.owner.address.latLng.lng,
                    },
                },
                createdAt: new Date(e.raffle.owner.createdAt + "Z"),
            },
            plan: {
                id: e.raffle.plan.id,
                title: e.raffle.plan.title,
                limit: e.raffle.plan.limit || 0,
                price: e.raffle.plan.price || 0,
            },
            title: e.raffle.title,
            objective: e.raffle.objective,
            images: e.raffle.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            ticketPrice: e.raffle.ticketPrice || 0,
            endDate: new Date(parseInt(e.raffle.endDate.split("-")[0], 10), parseInt(e.raffle.endDate.split("-")[1], 10) - 1, parseInt(e.raffle.endDate.split("-")[2], 10)),
            goal: e.raffle.goal || 0,
            amountOfPrizes: e.raffle.amountOfPrizes || 0,
            prizes: e.raffle.prizes.map((e: any) => e),
            forceResult: !!e.raffle.forceResult,
            finished: !!e.raffle.finished,
            ticketsSold: e.raffle.ticketsSold || 0,
            transferStatus: e.raffle.transferStatus,
            planPaymentStatus: e.raffle.planPaymentStatus,
            createdAt: new Date(parseInt(e.raffle.createdAt.split("-")[0], 10), parseInt(e.raffle.createdAt.split("-")[1], 10) - 1, parseInt(e.raffle.createdAt.split("-")[2], 10)),
        },
    }));
}

export async function confirmPrize(id: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        id: id,
    };
    await makeRequest({name: "confirmPrize", args, progress});
    return undefined;
}

export async function getPlan(id: string, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPlan", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        limit: ret.limit || 0,
        price: ret.price || 0,
    };
}

export async function getPlans(pageOffset: number, progress?: (progress: number) => void): Promise<Plan[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getPlans", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        limit: e.limit || 0,
        price: e.price || 0,
    }));
}

export async function createPlan(newPlan: NewPlan, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        newPlan: {
            title: newPlan.title,
            limit: newPlan.limit || 0,
            price: newPlan.price || 0,
        },
    };
    const ret = await makeRequest({name: "createPlan", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        limit: ret.limit || 0,
        price: ret.price || 0,
    };
}

export async function editPlan(id: string, editPlan: EditPlan, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        id: id,
        editPlan: {
            title: editPlan.title,
            limit: editPlan.limit || 0,
        },
    };
    const ret = await makeRequest({name: "editPlan", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        limit: ret.limit || 0,
        price: ret.price || 0,
    };
}

export async function deletePlan(id: string, progress?: (progress: number) => void): Promise<Plan> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePlan", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        limit: ret.limit || 0,
        price: ret.price || 0,
    };
}

export async function getRaffle(id: string, progress?: (progress: number) => void): Promise<Raffle> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getRaffle", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        planPaymentStatus: ret.planPaymentStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
    };
}

export async function getRaffles(textFilter: string | null, pageOffset: number, progress?: (progress: number) => void): Promise<Raffle[]> {
    const args = {
        textFilter: textFilter === null || textFilter === undefined ? null : textFilter,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getRaffles", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            email: e.owner.email,
            name: e.owner.name,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            phone: e.owner.phone,
            cpf: e.owner.cpf === null || e.owner.cpf === undefined ? null : e.owner.cpf,
            birthdate: e.owner.birthdate === null || e.owner.birthdate === undefined ? null : new Date(parseInt(e.owner.birthdate.split("-")[0], 10), parseInt(e.owner.birthdate.split("-")[1], 10) - 1, parseInt(e.owner.birthdate.split("-")[2], 10)),
            seller: e.owner.seller === null || e.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: e.owner.seller.documentFrontImage.thumb.width || 0,
                        height: e.owner.seller.documentFrontImage.thumb.height || 0,
                        url: e.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: e.owner.seller.documentFrontImage.width || 0,
                    height: e.owner.seller.documentFrontImage.height || 0,
                    url: e.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: e.owner.seller.documentBackImage.thumb.width || 0,
                        height: e.owner.seller.documentBackImage.thumb.height || 0,
                        url: e.owner.seller.documentBackImage.thumb.url,
                    },
                    width: e.owner.seller.documentBackImage.width || 0,
                    height: e.owner.seller.documentBackImage.height || 0,
                    url: e.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: e.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: e.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: e.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: e.owner.seller.photoHoldingDocument.width || 0,
                    height: e.owner.seller.photoHoldingDocument.height || 0,
                    url: e.owner.seller.photoHoldingDocument.url,
                },
                approved: e.owner.seller.approved,
                bankAccount: e.owner.seller.bankAccount === null || e.owner.seller.bankAccount === undefined ? null : {
                    id: e.owner.seller.bankAccount.id,
                    bankCode: e.owner.seller.bankAccount.bankCode,
                    agency: e.owner.seller.bankAccount.agency,
                    agencyDv: e.owner.seller.bankAccount.agencyDv === null || e.owner.seller.bankAccount.agencyDv === undefined ? null : e.owner.seller.bankAccount.agencyDv,
                    account: e.owner.seller.bankAccount.account,
                    accountDv: e.owner.seller.bankAccount.accountDv === null || e.owner.seller.bankAccount.accountDv === undefined ? null : e.owner.seller.bankAccount.accountDv,
                    type: e.owner.seller.bankAccount.type,
                },
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                zipcode: e.owner.address.zipcode,
                street: e.owner.address.street,
                streetNumber: e.owner.address.streetNumber,
                complementary: e.owner.address.complementary === null || e.owner.address.complementary === undefined ? null : e.owner.address.complementary,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                state: e.owner.address.state,
                countryCode: e.owner.address.countryCode,
                latLng: e.owner.address.latLng === null || e.owner.address.latLng === undefined ? null : {
                    lat: e.owner.address.latLng.lat,
                    lng: e.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
        },
        plan: {
            id: e.plan.id,
            title: e.plan.title,
            limit: e.plan.limit || 0,
            price: e.plan.price || 0,
        },
        title: e.title,
        objective: e.objective,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: e.ticketPrice || 0,
        endDate: new Date(parseInt(e.endDate.split("-")[0], 10), parseInt(e.endDate.split("-")[1], 10) - 1, parseInt(e.endDate.split("-")[2], 10)),
        goal: e.goal || 0,
        amountOfPrizes: e.amountOfPrizes || 0,
        prizes: e.prizes.map((e: any) => e),
        forceResult: !!e.forceResult,
        finished: !!e.finished,
        ticketsSold: e.ticketsSold || 0,
        transferStatus: e.transferStatus,
        planPaymentStatus: e.planPaymentStatus,
        createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
    }));
}

export async function deleteRaffle(id: string, progress?: (progress: number) => void): Promise<Raffle> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteRaffle", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        planPaymentStatus: ret.planPaymentStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
    };
}

export async function getAllRaffleResult(pageOffset: number, progress?: (progress: number) => void): Promise<RaffleResult[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllRaffleResult", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        first: e.first || 0,
        second: e.second || 0,
        third: e.third || 0,
        fourth: e.fourth || 0,
        fifth: e.fifth || 0,
        prizeDate: new Date(parseInt(e.prizeDate.split("-")[0], 10), parseInt(e.prizeDate.split("-")[1], 10) - 1, parseInt(e.prizeDate.split("-")[2], 10)),
        contestNumber: e.contestNumber || 0,
    }));
}

export async function addRaffleResult(newRaffleResult: NewRaffleResult, progress?: (progress: number) => void): Promise<RaffleResult> {
    const args = {
        newRaffleResult: {
            first: newRaffleResult.first || 0,
            second: newRaffleResult.second || 0,
            third: newRaffleResult.third || 0,
            fourth: newRaffleResult.fourth || 0,
            fifth: newRaffleResult.fifth || 0,
            prizeDate: typeof(newRaffleResult.prizeDate) === "string" ? new Date(new Date(newRaffleResult.prizeDate).getTime() - new Date(newRaffleResult.prizeDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newRaffleResult.prizeDate.getTime() - newRaffleResult.prizeDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            contestNumber: newRaffleResult.contestNumber || 0,
        },
    };
    const ret = await makeRequest({name: "addRaffleResult", args, progress});
    return {
        id: ret.id,
        first: ret.first || 0,
        second: ret.second || 0,
        third: ret.third || 0,
        fourth: ret.fourth || 0,
        fifth: ret.fifth || 0,
        prizeDate: new Date(parseInt(ret.prizeDate.split("-")[0], 10), parseInt(ret.prizeDate.split("-")[1], 10) - 1, parseInt(ret.prizeDate.split("-")[2], 10)),
        contestNumber: ret.contestNumber || 0,
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getCurrentUserRaffles(pageOffset: number, progress?: (progress: number) => void): Promise<Raffle[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCurrentUserRaffles", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            email: e.owner.email,
            name: e.owner.name,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            phone: e.owner.phone,
            cpf: e.owner.cpf === null || e.owner.cpf === undefined ? null : e.owner.cpf,
            birthdate: e.owner.birthdate === null || e.owner.birthdate === undefined ? null : new Date(parseInt(e.owner.birthdate.split("-")[0], 10), parseInt(e.owner.birthdate.split("-")[1], 10) - 1, parseInt(e.owner.birthdate.split("-")[2], 10)),
            seller: e.owner.seller === null || e.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: e.owner.seller.documentFrontImage.thumb.width || 0,
                        height: e.owner.seller.documentFrontImage.thumb.height || 0,
                        url: e.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: e.owner.seller.documentFrontImage.width || 0,
                    height: e.owner.seller.documentFrontImage.height || 0,
                    url: e.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: e.owner.seller.documentBackImage.thumb.width || 0,
                        height: e.owner.seller.documentBackImage.thumb.height || 0,
                        url: e.owner.seller.documentBackImage.thumb.url,
                    },
                    width: e.owner.seller.documentBackImage.width || 0,
                    height: e.owner.seller.documentBackImage.height || 0,
                    url: e.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: e.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: e.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: e.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: e.owner.seller.photoHoldingDocument.width || 0,
                    height: e.owner.seller.photoHoldingDocument.height || 0,
                    url: e.owner.seller.photoHoldingDocument.url,
                },
                approved: e.owner.seller.approved,
                bankAccount: e.owner.seller.bankAccount === null || e.owner.seller.bankAccount === undefined ? null : {
                    id: e.owner.seller.bankAccount.id,
                    bankCode: e.owner.seller.bankAccount.bankCode,
                    agency: e.owner.seller.bankAccount.agency,
                    agencyDv: e.owner.seller.bankAccount.agencyDv === null || e.owner.seller.bankAccount.agencyDv === undefined ? null : e.owner.seller.bankAccount.agencyDv,
                    account: e.owner.seller.bankAccount.account,
                    accountDv: e.owner.seller.bankAccount.accountDv === null || e.owner.seller.bankAccount.accountDv === undefined ? null : e.owner.seller.bankAccount.accountDv,
                    type: e.owner.seller.bankAccount.type,
                },
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                zipcode: e.owner.address.zipcode,
                street: e.owner.address.street,
                streetNumber: e.owner.address.streetNumber,
                complementary: e.owner.address.complementary === null || e.owner.address.complementary === undefined ? null : e.owner.address.complementary,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                state: e.owner.address.state,
                countryCode: e.owner.address.countryCode,
                latLng: e.owner.address.latLng === null || e.owner.address.latLng === undefined ? null : {
                    lat: e.owner.address.latLng.lat,
                    lng: e.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
        },
        plan: {
            id: e.plan.id,
            title: e.plan.title,
            limit: e.plan.limit || 0,
            price: e.plan.price || 0,
        },
        title: e.title,
        objective: e.objective,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: e.ticketPrice || 0,
        endDate: new Date(parseInt(e.endDate.split("-")[0], 10), parseInt(e.endDate.split("-")[1], 10) - 1, parseInt(e.endDate.split("-")[2], 10)),
        goal: e.goal || 0,
        amountOfPrizes: e.amountOfPrizes || 0,
        prizes: e.prizes.map((e: any) => e),
        forceResult: !!e.forceResult,
        finished: !!e.finished,
        ticketsSold: e.ticketsSold || 0,
        transferStatus: e.transferStatus,
        planPaymentStatus: e.planPaymentStatus,
        createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
    }));
}

export async function getRafflesAvailableForWithdrawal(pageOffset: number, progress?: (progress: number) => void): Promise<RaffleAvailableForWithdrawal[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getRafflesAvailableForWithdrawal", args, progress});
    return ret.map((e: any) => ({
        raffle: {
            id: e.raffle.id,
            owner: {
                id: e.raffle.owner.id,
                email: e.raffle.owner.email,
                name: e.raffle.owner.name,
                image: e.raffle.owner.image === null || e.raffle.owner.image === undefined ? null : {
                    thumb: {
                        width: e.raffle.owner.image.thumb.width || 0,
                        height: e.raffle.owner.image.thumb.height || 0,
                        url: e.raffle.owner.image.thumb.url,
                    },
                    width: e.raffle.owner.image.width || 0,
                    height: e.raffle.owner.image.height || 0,
                    url: e.raffle.owner.image.url,
                },
                phone: e.raffle.owner.phone,
                cpf: e.raffle.owner.cpf === null || e.raffle.owner.cpf === undefined ? null : e.raffle.owner.cpf,
                birthdate: e.raffle.owner.birthdate === null || e.raffle.owner.birthdate === undefined ? null : new Date(parseInt(e.raffle.owner.birthdate.split("-")[0], 10), parseInt(e.raffle.owner.birthdate.split("-")[1], 10) - 1, parseInt(e.raffle.owner.birthdate.split("-")[2], 10)),
                seller: e.raffle.owner.seller === null || e.raffle.owner.seller === undefined ? null : {
                    documentFrontImage: {
                        thumb: {
                            width: e.raffle.owner.seller.documentFrontImage.thumb.width || 0,
                            height: e.raffle.owner.seller.documentFrontImage.thumb.height || 0,
                            url: e.raffle.owner.seller.documentFrontImage.thumb.url,
                        },
                        width: e.raffle.owner.seller.documentFrontImage.width || 0,
                        height: e.raffle.owner.seller.documentFrontImage.height || 0,
                        url: e.raffle.owner.seller.documentFrontImage.url,
                    },
                    documentBackImage: {
                        thumb: {
                            width: e.raffle.owner.seller.documentBackImage.thumb.width || 0,
                            height: e.raffle.owner.seller.documentBackImage.thumb.height || 0,
                            url: e.raffle.owner.seller.documentBackImage.thumb.url,
                        },
                        width: e.raffle.owner.seller.documentBackImage.width || 0,
                        height: e.raffle.owner.seller.documentBackImage.height || 0,
                        url: e.raffle.owner.seller.documentBackImage.url,
                    },
                    photoHoldingDocument: {
                        thumb: {
                            width: e.raffle.owner.seller.photoHoldingDocument.thumb.width || 0,
                            height: e.raffle.owner.seller.photoHoldingDocument.thumb.height || 0,
                            url: e.raffle.owner.seller.photoHoldingDocument.thumb.url,
                        },
                        width: e.raffle.owner.seller.photoHoldingDocument.width || 0,
                        height: e.raffle.owner.seller.photoHoldingDocument.height || 0,
                        url: e.raffle.owner.seller.photoHoldingDocument.url,
                    },
                    approved: e.raffle.owner.seller.approved,
                    bankAccount: e.raffle.owner.seller.bankAccount === null || e.raffle.owner.seller.bankAccount === undefined ? null : {
                        id: e.raffle.owner.seller.bankAccount.id,
                        bankCode: e.raffle.owner.seller.bankAccount.bankCode,
                        agency: e.raffle.owner.seller.bankAccount.agency,
                        agencyDv: e.raffle.owner.seller.bankAccount.agencyDv === null || e.raffle.owner.seller.bankAccount.agencyDv === undefined ? null : e.raffle.owner.seller.bankAccount.agencyDv,
                        account: e.raffle.owner.seller.bankAccount.account,
                        accountDv: e.raffle.owner.seller.bankAccount.accountDv === null || e.raffle.owner.seller.bankAccount.accountDv === undefined ? null : e.raffle.owner.seller.bankAccount.accountDv,
                        type: e.raffle.owner.seller.bankAccount.type,
                    },
                },
                address: e.raffle.owner.address === null || e.raffle.owner.address === undefined ? null : {
                    zipcode: e.raffle.owner.address.zipcode,
                    street: e.raffle.owner.address.street,
                    streetNumber: e.raffle.owner.address.streetNumber,
                    complementary: e.raffle.owner.address.complementary === null || e.raffle.owner.address.complementary === undefined ? null : e.raffle.owner.address.complementary,
                    neighborhood: e.raffle.owner.address.neighborhood,
                    city: e.raffle.owner.address.city,
                    state: e.raffle.owner.address.state,
                    countryCode: e.raffle.owner.address.countryCode,
                    latLng: e.raffle.owner.address.latLng === null || e.raffle.owner.address.latLng === undefined ? null : {
                        lat: e.raffle.owner.address.latLng.lat,
                        lng: e.raffle.owner.address.latLng.lng,
                    },
                },
                createdAt: new Date(e.raffle.owner.createdAt + "Z"),
            },
            plan: {
                id: e.raffle.plan.id,
                title: e.raffle.plan.title,
                limit: e.raffle.plan.limit || 0,
                price: e.raffle.plan.price || 0,
            },
            title: e.raffle.title,
            objective: e.raffle.objective,
            images: e.raffle.images.map((e: any) => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
            ticketPrice: e.raffle.ticketPrice || 0,
            endDate: new Date(parseInt(e.raffle.endDate.split("-")[0], 10), parseInt(e.raffle.endDate.split("-")[1], 10) - 1, parseInt(e.raffle.endDate.split("-")[2], 10)),
            goal: e.raffle.goal || 0,
            amountOfPrizes: e.raffle.amountOfPrizes || 0,
            prizes: e.raffle.prizes.map((e: any) => e),
            forceResult: !!e.raffle.forceResult,
            finished: !!e.raffle.finished,
            ticketsSold: e.raffle.ticketsSold || 0,
            transferStatus: e.raffle.transferStatus,
            planPaymentStatus: e.raffle.planPaymentStatus,
            createdAt: new Date(parseInt(e.raffle.createdAt.split("-")[0], 10), parseInt(e.raffle.createdAt.split("-")[1], 10) - 1, parseInt(e.raffle.createdAt.split("-")[2], 10)),
        },
        availableValue: e.availableValue || 0,
    }));
}

export async function getRafflesWithPurchasedTickets(pageOffset: number, progress?: (progress: number) => void): Promise<Raffle[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getRafflesWithPurchasedTickets", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            email: e.owner.email,
            name: e.owner.name,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            phone: e.owner.phone,
            cpf: e.owner.cpf === null || e.owner.cpf === undefined ? null : e.owner.cpf,
            birthdate: e.owner.birthdate === null || e.owner.birthdate === undefined ? null : new Date(parseInt(e.owner.birthdate.split("-")[0], 10), parseInt(e.owner.birthdate.split("-")[1], 10) - 1, parseInt(e.owner.birthdate.split("-")[2], 10)),
            seller: e.owner.seller === null || e.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: e.owner.seller.documentFrontImage.thumb.width || 0,
                        height: e.owner.seller.documentFrontImage.thumb.height || 0,
                        url: e.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: e.owner.seller.documentFrontImage.width || 0,
                    height: e.owner.seller.documentFrontImage.height || 0,
                    url: e.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: e.owner.seller.documentBackImage.thumb.width || 0,
                        height: e.owner.seller.documentBackImage.thumb.height || 0,
                        url: e.owner.seller.documentBackImage.thumb.url,
                    },
                    width: e.owner.seller.documentBackImage.width || 0,
                    height: e.owner.seller.documentBackImage.height || 0,
                    url: e.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: e.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: e.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: e.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: e.owner.seller.photoHoldingDocument.width || 0,
                    height: e.owner.seller.photoHoldingDocument.height || 0,
                    url: e.owner.seller.photoHoldingDocument.url,
                },
                approved: e.owner.seller.approved,
                bankAccount: e.owner.seller.bankAccount === null || e.owner.seller.bankAccount === undefined ? null : {
                    id: e.owner.seller.bankAccount.id,
                    bankCode: e.owner.seller.bankAccount.bankCode,
                    agency: e.owner.seller.bankAccount.agency,
                    agencyDv: e.owner.seller.bankAccount.agencyDv === null || e.owner.seller.bankAccount.agencyDv === undefined ? null : e.owner.seller.bankAccount.agencyDv,
                    account: e.owner.seller.bankAccount.account,
                    accountDv: e.owner.seller.bankAccount.accountDv === null || e.owner.seller.bankAccount.accountDv === undefined ? null : e.owner.seller.bankAccount.accountDv,
                    type: e.owner.seller.bankAccount.type,
                },
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                zipcode: e.owner.address.zipcode,
                street: e.owner.address.street,
                streetNumber: e.owner.address.streetNumber,
                complementary: e.owner.address.complementary === null || e.owner.address.complementary === undefined ? null : e.owner.address.complementary,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                state: e.owner.address.state,
                countryCode: e.owner.address.countryCode,
                latLng: e.owner.address.latLng === null || e.owner.address.latLng === undefined ? null : {
                    lat: e.owner.address.latLng.lat,
                    lng: e.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
        },
        plan: {
            id: e.plan.id,
            title: e.plan.title,
            limit: e.plan.limit || 0,
            price: e.plan.price || 0,
        },
        title: e.title,
        objective: e.objective,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: e.ticketPrice || 0,
        endDate: new Date(parseInt(e.endDate.split("-")[0], 10), parseInt(e.endDate.split("-")[1], 10) - 1, parseInt(e.endDate.split("-")[2], 10)),
        goal: e.goal || 0,
        amountOfPrizes: e.amountOfPrizes || 0,
        prizes: e.prizes.map((e: any) => e),
        forceResult: !!e.forceResult,
        finished: !!e.finished,
        ticketsSold: e.ticketsSold || 0,
        transferStatus: e.transferStatus,
        planPaymentStatus: e.planPaymentStatus,
        createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
    }));
}

export async function getRaffleDynamicLink(id: string, progress?: (progress: number) => void): Promise<string | null> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getRaffleDynamicLink", args, progress});
    return ret === null || ret === undefined ? null : ret;
}

export async function getRaffleTicketsStatistics(raffleId: string, progress?: (progress: number) => void): Promise<RaffleTicketsStatistics> {
    const args = {
        raffleId: raffleId,
    };
    const ret = await makeRequest({name: "getRaffleTicketsStatistics", args, progress});
    return {
        purchased: ret.purchased || 0,
        reserved: ret.reserved || 0,
    };
}

export async function getCountCurrentUserHasEndedRaffles(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getCountCurrentUserHasEndedRaffles", args: {}, progress});
    return ret || 0;
}

export async function createRaffle(newRaffle: NewRaffle, progress?: (progress: number) => void): Promise<Raffle> {
    const args = {
        newRaffle: {
            title: newRaffle.title,
            objective: newRaffle.objective,
            images: newRaffle.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            ticketPrice: newRaffle.ticketPrice || 0,
            endDate: typeof(newRaffle.endDate) === "string" ? new Date(new Date(newRaffle.endDate).getTime() - new Date(newRaffle.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newRaffle.endDate.getTime() - newRaffle.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            goal: newRaffle.goal || 0,
            prizes: newRaffle.prizes.map(e => e),
            forceResult: !!newRaffle.forceResult,
            planId: newRaffle.planId,
        },
    };
    const ret = await makeRequest({name: "createRaffle", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        planPaymentStatus: ret.planPaymentStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
    };
}

export async function createRaffleWithPlanByCreditCard(newRaffle: NewRaffle, creditCardId: string | null, progress?: (progress: number) => void): Promise<RaffleWithTransaction> {
    const args = {
        newRaffle: {
            title: newRaffle.title,
            objective: newRaffle.objective,
            images: newRaffle.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            ticketPrice: newRaffle.ticketPrice || 0,
            endDate: typeof(newRaffle.endDate) === "string" ? new Date(new Date(newRaffle.endDate).getTime() - new Date(newRaffle.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newRaffle.endDate.getTime() - newRaffle.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            goal: newRaffle.goal || 0,
            prizes: newRaffle.prizes.map(e => e),
            forceResult: !!newRaffle.forceResult,
            planId: newRaffle.planId,
        },
        creditCardId: creditCardId === null || creditCardId === undefined ? null : creditCardId,
    };
    const ret = await makeRequest({name: "createRaffleWithPlanByCreditCard", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        planPaymentStatus: ret.planPaymentStatus,
        transaction: {
            id: ret.transaction.id,
            boletoUrl: ret.transaction.boletoUrl === null || ret.transaction.boletoUrl === undefined ? null : ret.transaction.boletoUrl,
            boletoBarcode: ret.transaction.boletoBarcode === null || ret.transaction.boletoBarcode === undefined ? null : ret.transaction.boletoBarcode,
            boletoExpirationDate: ret.transaction.boletoExpirationDate === null || ret.transaction.boletoExpirationDate === undefined ? null : ret.transaction.boletoExpirationDate,
            paymentMethod: ret.transaction.paymentMethod,
            status: ret.transaction.status,
            statusReason: ret.transaction.statusReason,
            metadata: ret.transaction.metadata,
            pixQRCode: ret.transaction.pixQRCode === null || ret.transaction.pixQRCode === undefined ? null : ret.transaction.pixQRCode,
            qrExpirationDate: ret.transaction.qrExpirationDate === null || ret.transaction.qrExpirationDate === undefined ? null : ret.transaction.qrExpirationDate,
            createdAt: new Date(ret.transaction.createdAt + "Z"),
            amount: ret.transaction.amount || 0,
            lastFourDigits: ret.transaction.lastFourDigits === null || ret.transaction.lastFourDigits === undefined ? null : ret.transaction.lastFourDigits,
            cardBrand: ret.transaction.cardBrand === null || ret.transaction.cardBrand === undefined ? null : ret.transaction.cardBrand,
        },
    };
}

export async function createRaffleWithPlanByBoleto(newRaffle: NewRaffle, progress?: (progress: number) => void): Promise<RaffleWithTransaction> {
    const args = {
        newRaffle: {
            title: newRaffle.title,
            objective: newRaffle.objective,
            images: newRaffle.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            ticketPrice: newRaffle.ticketPrice || 0,
            endDate: typeof(newRaffle.endDate) === "string" ? new Date(new Date(newRaffle.endDate).getTime() - new Date(newRaffle.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newRaffle.endDate.getTime() - newRaffle.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            goal: newRaffle.goal || 0,
            prizes: newRaffle.prizes.map(e => e),
            forceResult: !!newRaffle.forceResult,
            planId: newRaffle.planId,
        },
    };
    const ret = await makeRequest({name: "createRaffleWithPlanByBoleto", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        planPaymentStatus: ret.planPaymentStatus,
        transaction: {
            id: ret.transaction.id,
            boletoUrl: ret.transaction.boletoUrl === null || ret.transaction.boletoUrl === undefined ? null : ret.transaction.boletoUrl,
            boletoBarcode: ret.transaction.boletoBarcode === null || ret.transaction.boletoBarcode === undefined ? null : ret.transaction.boletoBarcode,
            boletoExpirationDate: ret.transaction.boletoExpirationDate === null || ret.transaction.boletoExpirationDate === undefined ? null : ret.transaction.boletoExpirationDate,
            paymentMethod: ret.transaction.paymentMethod,
            status: ret.transaction.status,
            statusReason: ret.transaction.statusReason,
            metadata: ret.transaction.metadata,
            pixQRCode: ret.transaction.pixQRCode === null || ret.transaction.pixQRCode === undefined ? null : ret.transaction.pixQRCode,
            qrExpirationDate: ret.transaction.qrExpirationDate === null || ret.transaction.qrExpirationDate === undefined ? null : ret.transaction.qrExpirationDate,
            createdAt: new Date(ret.transaction.createdAt + "Z"),
            amount: ret.transaction.amount || 0,
            lastFourDigits: ret.transaction.lastFourDigits === null || ret.transaction.lastFourDigits === undefined ? null : ret.transaction.lastFourDigits,
            cardBrand: ret.transaction.cardBrand === null || ret.transaction.cardBrand === undefined ? null : ret.transaction.cardBrand,
        },
    };
}

export async function createRaffleWithPlanByPix(newRaffle: NewRaffle, progress?: (progress: number) => void): Promise<RaffleWithTransaction> {
    const args = {
        newRaffle: {
            title: newRaffle.title,
            objective: newRaffle.objective,
            images: newRaffle.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            ticketPrice: newRaffle.ticketPrice || 0,
            endDate: typeof(newRaffle.endDate) === "string" ? new Date(new Date(newRaffle.endDate).getTime() - new Date(newRaffle.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newRaffle.endDate.getTime() - newRaffle.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            goal: newRaffle.goal || 0,
            prizes: newRaffle.prizes.map(e => e),
            forceResult: !!newRaffle.forceResult,
            planId: newRaffle.planId,
        },
    };
    const ret = await makeRequest({name: "createRaffleWithPlanByPix", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        planPaymentStatus: ret.planPaymentStatus,
        transaction: {
            id: ret.transaction.id,
            boletoUrl: ret.transaction.boletoUrl === null || ret.transaction.boletoUrl === undefined ? null : ret.transaction.boletoUrl,
            boletoBarcode: ret.transaction.boletoBarcode === null || ret.transaction.boletoBarcode === undefined ? null : ret.transaction.boletoBarcode,
            boletoExpirationDate: ret.transaction.boletoExpirationDate === null || ret.transaction.boletoExpirationDate === undefined ? null : ret.transaction.boletoExpirationDate,
            paymentMethod: ret.transaction.paymentMethod,
            status: ret.transaction.status,
            statusReason: ret.transaction.statusReason,
            metadata: ret.transaction.metadata,
            pixQRCode: ret.transaction.pixQRCode === null || ret.transaction.pixQRCode === undefined ? null : ret.transaction.pixQRCode,
            qrExpirationDate: ret.transaction.qrExpirationDate === null || ret.transaction.qrExpirationDate === undefined ? null : ret.transaction.qrExpirationDate,
            createdAt: new Date(ret.transaction.createdAt + "Z"),
            amount: ret.transaction.amount || 0,
            lastFourDigits: ret.transaction.lastFourDigits === null || ret.transaction.lastFourDigits === undefined ? null : ret.transaction.lastFourDigits,
            cardBrand: ret.transaction.cardBrand === null || ret.transaction.cardBrand === undefined ? null : ret.transaction.cardBrand,
        },
    };
}

export async function editFullRaffle(id: string, editRag: EditFullRaffle, progress?: (progress: number) => void): Promise<Raffle> {
    const args = {
        id: id,
        editRag: {
            title: editRag.title,
            objective: editRag.objective,
            images: editRag.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            endDate: typeof(editRag.endDate) === "string" ? new Date(new Date(editRag.endDate).getTime() - new Date(editRag.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editRag.endDate.getTime() - editRag.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            goal: editRag.goal || 0,
        },
    };
    const ret = await makeRequest({name: "editFullRaffle", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        planPaymentStatus: ret.planPaymentStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
    };
}

export async function editRestrictedRaffle(id: string, editRag: EditRestrictedRaffle, progress?: (progress: number) => void): Promise<Raffle> {
    const args = {
        id: id,
        editRag: {
            images: editRag.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            goal: editRag.goal || 0,
        },
    };
    const ret = await makeRequest({name: "editRestrictedRaffle", args, progress});
    return {
        id: ret.id,
        owner: {
            id: ret.owner.id,
            email: ret.owner.email,
            name: ret.owner.name,
            image: ret.owner.image === null || ret.owner.image === undefined ? null : {
                thumb: {
                    width: ret.owner.image.thumb.width || 0,
                    height: ret.owner.image.thumb.height || 0,
                    url: ret.owner.image.thumb.url,
                },
                width: ret.owner.image.width || 0,
                height: ret.owner.image.height || 0,
                url: ret.owner.image.url,
            },
            phone: ret.owner.phone,
            cpf: ret.owner.cpf === null || ret.owner.cpf === undefined ? null : ret.owner.cpf,
            birthdate: ret.owner.birthdate === null || ret.owner.birthdate === undefined ? null : new Date(parseInt(ret.owner.birthdate.split("-")[0], 10), parseInt(ret.owner.birthdate.split("-")[1], 10) - 1, parseInt(ret.owner.birthdate.split("-")[2], 10)),
            seller: ret.owner.seller === null || ret.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.owner.seller.documentFrontImage.thumb.width || 0,
                        height: ret.owner.seller.documentFrontImage.thumb.height || 0,
                        url: ret.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.owner.seller.documentFrontImage.width || 0,
                    height: ret.owner.seller.documentFrontImage.height || 0,
                    url: ret.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.owner.seller.documentBackImage.thumb.width || 0,
                        height: ret.owner.seller.documentBackImage.thumb.height || 0,
                        url: ret.owner.seller.documentBackImage.thumb.url,
                    },
                    width: ret.owner.seller.documentBackImage.width || 0,
                    height: ret.owner.seller.documentBackImage.height || 0,
                    url: ret.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.owner.seller.photoHoldingDocument.width || 0,
                    height: ret.owner.seller.photoHoldingDocument.height || 0,
                    url: ret.owner.seller.photoHoldingDocument.url,
                },
                approved: ret.owner.seller.approved,
                bankAccount: ret.owner.seller.bankAccount === null || ret.owner.seller.bankAccount === undefined ? null : {
                    id: ret.owner.seller.bankAccount.id,
                    bankCode: ret.owner.seller.bankAccount.bankCode,
                    agency: ret.owner.seller.bankAccount.agency,
                    agencyDv: ret.owner.seller.bankAccount.agencyDv === null || ret.owner.seller.bankAccount.agencyDv === undefined ? null : ret.owner.seller.bankAccount.agencyDv,
                    account: ret.owner.seller.bankAccount.account,
                    accountDv: ret.owner.seller.bankAccount.accountDv === null || ret.owner.seller.bankAccount.accountDv === undefined ? null : ret.owner.seller.bankAccount.accountDv,
                    type: ret.owner.seller.bankAccount.type,
                },
            },
            address: ret.owner.address === null || ret.owner.address === undefined ? null : {
                zipcode: ret.owner.address.zipcode,
                street: ret.owner.address.street,
                streetNumber: ret.owner.address.streetNumber,
                complementary: ret.owner.address.complementary === null || ret.owner.address.complementary === undefined ? null : ret.owner.address.complementary,
                neighborhood: ret.owner.address.neighborhood,
                city: ret.owner.address.city,
                state: ret.owner.address.state,
                countryCode: ret.owner.address.countryCode,
                latLng: ret.owner.address.latLng === null || ret.owner.address.latLng === undefined ? null : {
                    lat: ret.owner.address.latLng.lat,
                    lng: ret.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.owner.createdAt + "Z"),
        },
        plan: {
            id: ret.plan.id,
            title: ret.plan.title,
            limit: ret.plan.limit || 0,
            price: ret.plan.price || 0,
        },
        title: ret.title,
        objective: ret.objective,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: ret.ticketPrice || 0,
        endDate: new Date(parseInt(ret.endDate.split("-")[0], 10), parseInt(ret.endDate.split("-")[1], 10) - 1, parseInt(ret.endDate.split("-")[2], 10)),
        goal: ret.goal || 0,
        amountOfPrizes: ret.amountOfPrizes || 0,
        prizes: ret.prizes.map((e: any) => e),
        forceResult: !!ret.forceResult,
        finished: !!ret.finished,
        ticketsSold: ret.ticketsSold || 0,
        transferStatus: ret.transferStatus,
        planPaymentStatus: ret.planPaymentStatus,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
    };
}

export async function setCashOut(ids: string[], progress?: (progress: number) => void): Promise<void> {
    const args = {
        ids: ids.map(e => e),
    };
    await makeRequest({name: "setCashOut", args, progress});
    return undefined;
}

export async function getCurrentUser(progress?: (progress: number) => void): Promise<User> {
    const ret = await makeRequest({name: "getCurrentUser", args: {}, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function userLogin(email: string, password: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "userLogin", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function userUpdatePassword(currentPassword: string, newPassword: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        currentPassword: currentPassword,
        newPassword: newPassword,
    };
    const ret = await makeRequest({name: "userUpdatePassword", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createUser(newUSer: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUSer: {
            email: newUSer.email,
            password: newUSer.password,
            name: newUSer.name,
            image: newUSer.image === null || newUSer.image === undefined ? null : {
                bytes: newUSer.image.bytes === null || newUSer.image.bytes === undefined ? null : newUSer.image.bytes.toString("base64"),
                image: newUSer.image.image === null || newUSer.image.image === undefined ? null : {
                    thumb: {
                        width: newUSer.image.image.thumb.width || 0,
                        height: newUSer.image.image.thumb.height || 0,
                        url: newUSer.image.image.thumb.url,
                    },
                    width: newUSer.image.image.width || 0,
                    height: newUSer.image.image.height || 0,
                    url: newUSer.image.image.url,
                },
            },
            phone: newUSer.phone,
            cpf: newUSer.cpf === null || newUSer.cpf === undefined ? null : newUSer.cpf,
            birthdate: newUSer.birthdate === null || newUSer.birthdate === undefined ? null : typeof(newUSer.birthdate) === "string" ? new Date(new Date(newUSer.birthdate).getTime() - new Date(newUSer.birthdate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newUSer.birthdate.getTime() - newUSer.birthdate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function requestToSeller(newSeller: NewSeller, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newSeller: {
            documentFrontImage: {
                bytes: newSeller.documentFrontImage.bytes === null || newSeller.documentFrontImage.bytes === undefined ? null : newSeller.documentFrontImage.bytes.toString("base64"),
                image: newSeller.documentFrontImage.image === null || newSeller.documentFrontImage.image === undefined ? null : {
                    thumb: {
                        width: newSeller.documentFrontImage.image.thumb.width || 0,
                        height: newSeller.documentFrontImage.image.thumb.height || 0,
                        url: newSeller.documentFrontImage.image.thumb.url,
                    },
                    width: newSeller.documentFrontImage.image.width || 0,
                    height: newSeller.documentFrontImage.image.height || 0,
                    url: newSeller.documentFrontImage.image.url,
                },
            },
            documentBackImage: {
                bytes: newSeller.documentBackImage.bytes === null || newSeller.documentBackImage.bytes === undefined ? null : newSeller.documentBackImage.bytes.toString("base64"),
                image: newSeller.documentBackImage.image === null || newSeller.documentBackImage.image === undefined ? null : {
                    thumb: {
                        width: newSeller.documentBackImage.image.thumb.width || 0,
                        height: newSeller.documentBackImage.image.thumb.height || 0,
                        url: newSeller.documentBackImage.image.thumb.url,
                    },
                    width: newSeller.documentBackImage.image.width || 0,
                    height: newSeller.documentBackImage.image.height || 0,
                    url: newSeller.documentBackImage.image.url,
                },
            },
            photoHoldingDocument: {
                bytes: newSeller.photoHoldingDocument.bytes === null || newSeller.photoHoldingDocument.bytes === undefined ? null : newSeller.photoHoldingDocument.bytes.toString("base64"),
                image: newSeller.photoHoldingDocument.image === null || newSeller.photoHoldingDocument.image === undefined ? null : {
                    thumb: {
                        width: newSeller.photoHoldingDocument.image.thumb.width || 0,
                        height: newSeller.photoHoldingDocument.image.thumb.height || 0,
                        url: newSeller.photoHoldingDocument.image.thumb.url,
                    },
                    width: newSeller.photoHoldingDocument.image.width || 0,
                    height: newSeller.photoHoldingDocument.image.height || 0,
                    url: newSeller.photoHoldingDocument.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "requestToSeller", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createOrEditAddress(newAddress: NewAddress, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newAddress: {
            zipcode: newAddress.zipcode,
            street: newAddress.street,
            streetNumber: newAddress.streetNumber,
            complementary: newAddress.complementary === null || newAddress.complementary === undefined ? null : newAddress.complementary,
            neighborhood: newAddress.neighborhood,
            city: newAddress.city,
            state: newAddress.state,
            countryCode: newAddress.countryCode,
            latLng: newAddress.latLng === null || newAddress.latLng === undefined ? null : {
                lat: newAddress.latLng.lat,
                lng: newAddress.latLng.lng,
            },
        },
    };
    const ret = await makeRequest({name: "createOrEditAddress", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editUser(id: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editUser: {
            email: editUser.email,
            name: editUser.name,
            image: editUser.image === null || editUser.image === undefined ? null : {
                bytes: editUser.image.bytes === null || editUser.image.bytes === undefined ? null : editUser.image.bytes.toString("base64"),
                image: editUser.image.image === null || editUser.image.image === undefined ? null : {
                    thumb: {
                        width: editUser.image.image.thumb.width || 0,
                        height: editUser.image.image.thumb.height || 0,
                        url: editUser.image.image.thumb.url,
                    },
                    width: editUser.image.image.width || 0,
                    height: editUser.image.image.height || 0,
                    url: editUser.image.image.url,
                },
            },
            phone: editUser.phone,
            cpf: editUser.cpf === null || editUser.cpf === undefined ? null : editUser.cpf,
            birthdate: editUser.birthdate === null || editUser.birthdate === undefined ? null : typeof(editUser.birthdate) === "string" ? new Date(new Date(editUser.birthdate).getTime() - new Date(editUser.birthdate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editUser.birthdate.getTime() - editUser.birthdate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function logoutUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutUser", args: {}, progress});
    return undefined;
}

export async function getRequestTransferHistoryPaginated(pageOffset: number, progress?: (progress: number) => void): Promise<TransferHistory[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getRequestTransferHistoryPaginated", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        totalPrice: e.totalPrice || 0,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            phone: e.user.phone,
            cpf: e.user.cpf === null || e.user.cpf === undefined ? null : e.user.cpf,
            birthdate: e.user.birthdate === null || e.user.birthdate === undefined ? null : new Date(parseInt(e.user.birthdate.split("-")[0], 10), parseInt(e.user.birthdate.split("-")[1], 10) - 1, parseInt(e.user.birthdate.split("-")[2], 10)),
            seller: e.user.seller === null || e.user.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: e.user.seller.documentFrontImage.thumb.width || 0,
                        height: e.user.seller.documentFrontImage.thumb.height || 0,
                        url: e.user.seller.documentFrontImage.thumb.url,
                    },
                    width: e.user.seller.documentFrontImage.width || 0,
                    height: e.user.seller.documentFrontImage.height || 0,
                    url: e.user.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: e.user.seller.documentBackImage.thumb.width || 0,
                        height: e.user.seller.documentBackImage.thumb.height || 0,
                        url: e.user.seller.documentBackImage.thumb.url,
                    },
                    width: e.user.seller.documentBackImage.width || 0,
                    height: e.user.seller.documentBackImage.height || 0,
                    url: e.user.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: e.user.seller.photoHoldingDocument.thumb.width || 0,
                        height: e.user.seller.photoHoldingDocument.thumb.height || 0,
                        url: e.user.seller.photoHoldingDocument.thumb.url,
                    },
                    width: e.user.seller.photoHoldingDocument.width || 0,
                    height: e.user.seller.photoHoldingDocument.height || 0,
                    url: e.user.seller.photoHoldingDocument.url,
                },
                approved: e.user.seller.approved,
                bankAccount: e.user.seller.bankAccount === null || e.user.seller.bankAccount === undefined ? null : {
                    id: e.user.seller.bankAccount.id,
                    bankCode: e.user.seller.bankAccount.bankCode,
                    agency: e.user.seller.bankAccount.agency,
                    agencyDv: e.user.seller.bankAccount.agencyDv === null || e.user.seller.bankAccount.agencyDv === undefined ? null : e.user.seller.bankAccount.agencyDv,
                    account: e.user.seller.bankAccount.account,
                    accountDv: e.user.seller.bankAccount.accountDv === null || e.user.seller.bankAccount.accountDv === undefined ? null : e.user.seller.bankAccount.accountDv,
                    type: e.user.seller.bankAccount.type,
                },
            },
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
                latLng: e.user.address.latLng === null || e.user.address.latLng === undefined ? null : {
                    lat: e.user.address.latLng.lat,
                    lng: e.user.address.latLng.lng,
                },
            },
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        transferHistoryStatus: e.transferHistoryStatus,
        transferDate: new Date(parseInt(e.transferDate.split("-")[0], 10), parseInt(e.transferDate.split("-")[1], 10) - 1, parseInt(e.transferDate.split("-")[2], 10)),
    }));
}

export async function getTransferHistory(id: string, progress?: (progress: number) => void): Promise<TransferHistory> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getTransferHistory", args, progress});
    return {
        id: ret.id,
        totalPrice: ret.totalPrice || 0,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            phone: ret.user.phone,
            cpf: ret.user.cpf === null || ret.user.cpf === undefined ? null : ret.user.cpf,
            birthdate: ret.user.birthdate === null || ret.user.birthdate === undefined ? null : new Date(parseInt(ret.user.birthdate.split("-")[0], 10), parseInt(ret.user.birthdate.split("-")[1], 10) - 1, parseInt(ret.user.birthdate.split("-")[2], 10)),
            seller: ret.user.seller === null || ret.user.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.user.seller.documentFrontImage.thumb.width || 0,
                        height: ret.user.seller.documentFrontImage.thumb.height || 0,
                        url: ret.user.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.user.seller.documentFrontImage.width || 0,
                    height: ret.user.seller.documentFrontImage.height || 0,
                    url: ret.user.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.user.seller.documentBackImage.thumb.width || 0,
                        height: ret.user.seller.documentBackImage.thumb.height || 0,
                        url: ret.user.seller.documentBackImage.thumb.url,
                    },
                    width: ret.user.seller.documentBackImage.width || 0,
                    height: ret.user.seller.documentBackImage.height || 0,
                    url: ret.user.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.user.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.user.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.user.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.user.seller.photoHoldingDocument.width || 0,
                    height: ret.user.seller.photoHoldingDocument.height || 0,
                    url: ret.user.seller.photoHoldingDocument.url,
                },
                approved: ret.user.seller.approved,
                bankAccount: ret.user.seller.bankAccount === null || ret.user.seller.bankAccount === undefined ? null : {
                    id: ret.user.seller.bankAccount.id,
                    bankCode: ret.user.seller.bankAccount.bankCode,
                    agency: ret.user.seller.bankAccount.agency,
                    agencyDv: ret.user.seller.bankAccount.agencyDv === null || ret.user.seller.bankAccount.agencyDv === undefined ? null : ret.user.seller.bankAccount.agencyDv,
                    account: ret.user.seller.bankAccount.account,
                    accountDv: ret.user.seller.bankAccount.accountDv === null || ret.user.seller.bankAccount.accountDv === undefined ? null : ret.user.seller.bankAccount.accountDv,
                    type: ret.user.seller.bankAccount.type,
                },
            },
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
                latLng: ret.user.address.latLng === null || ret.user.address.latLng === undefined ? null : {
                    lat: ret.user.address.latLng.lat,
                    lng: ret.user.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        transferHistoryStatus: ret.transferHistoryStatus,
        transferDate: new Date(parseInt(ret.transferDate.split("-")[0], 10), parseInt(ret.transferDate.split("-")[1], 10) - 1, parseInt(ret.transferDate.split("-")[2], 10)),
    };
}

export async function getRafflesByTransferHistory(id: string, progress?: (progress: number) => void): Promise<Raffle[]> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getRafflesByTransferHistory", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        owner: {
            id: e.owner.id,
            email: e.owner.email,
            name: e.owner.name,
            image: e.owner.image === null || e.owner.image === undefined ? null : {
                thumb: {
                    width: e.owner.image.thumb.width || 0,
                    height: e.owner.image.thumb.height || 0,
                    url: e.owner.image.thumb.url,
                },
                width: e.owner.image.width || 0,
                height: e.owner.image.height || 0,
                url: e.owner.image.url,
            },
            phone: e.owner.phone,
            cpf: e.owner.cpf === null || e.owner.cpf === undefined ? null : e.owner.cpf,
            birthdate: e.owner.birthdate === null || e.owner.birthdate === undefined ? null : new Date(parseInt(e.owner.birthdate.split("-")[0], 10), parseInt(e.owner.birthdate.split("-")[1], 10) - 1, parseInt(e.owner.birthdate.split("-")[2], 10)),
            seller: e.owner.seller === null || e.owner.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: e.owner.seller.documentFrontImage.thumb.width || 0,
                        height: e.owner.seller.documentFrontImage.thumb.height || 0,
                        url: e.owner.seller.documentFrontImage.thumb.url,
                    },
                    width: e.owner.seller.documentFrontImage.width || 0,
                    height: e.owner.seller.documentFrontImage.height || 0,
                    url: e.owner.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: e.owner.seller.documentBackImage.thumb.width || 0,
                        height: e.owner.seller.documentBackImage.thumb.height || 0,
                        url: e.owner.seller.documentBackImage.thumb.url,
                    },
                    width: e.owner.seller.documentBackImage.width || 0,
                    height: e.owner.seller.documentBackImage.height || 0,
                    url: e.owner.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: e.owner.seller.photoHoldingDocument.thumb.width || 0,
                        height: e.owner.seller.photoHoldingDocument.thumb.height || 0,
                        url: e.owner.seller.photoHoldingDocument.thumb.url,
                    },
                    width: e.owner.seller.photoHoldingDocument.width || 0,
                    height: e.owner.seller.photoHoldingDocument.height || 0,
                    url: e.owner.seller.photoHoldingDocument.url,
                },
                approved: e.owner.seller.approved,
                bankAccount: e.owner.seller.bankAccount === null || e.owner.seller.bankAccount === undefined ? null : {
                    id: e.owner.seller.bankAccount.id,
                    bankCode: e.owner.seller.bankAccount.bankCode,
                    agency: e.owner.seller.bankAccount.agency,
                    agencyDv: e.owner.seller.bankAccount.agencyDv === null || e.owner.seller.bankAccount.agencyDv === undefined ? null : e.owner.seller.bankAccount.agencyDv,
                    account: e.owner.seller.bankAccount.account,
                    accountDv: e.owner.seller.bankAccount.accountDv === null || e.owner.seller.bankAccount.accountDv === undefined ? null : e.owner.seller.bankAccount.accountDv,
                    type: e.owner.seller.bankAccount.type,
                },
            },
            address: e.owner.address === null || e.owner.address === undefined ? null : {
                zipcode: e.owner.address.zipcode,
                street: e.owner.address.street,
                streetNumber: e.owner.address.streetNumber,
                complementary: e.owner.address.complementary === null || e.owner.address.complementary === undefined ? null : e.owner.address.complementary,
                neighborhood: e.owner.address.neighborhood,
                city: e.owner.address.city,
                state: e.owner.address.state,
                countryCode: e.owner.address.countryCode,
                latLng: e.owner.address.latLng === null || e.owner.address.latLng === undefined ? null : {
                    lat: e.owner.address.latLng.lat,
                    lng: e.owner.address.latLng.lng,
                },
            },
            createdAt: new Date(e.owner.createdAt + "Z"),
        },
        plan: {
            id: e.plan.id,
            title: e.plan.title,
            limit: e.plan.limit || 0,
            price: e.plan.price || 0,
        },
        title: e.title,
        objective: e.objective,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        ticketPrice: e.ticketPrice || 0,
        endDate: new Date(parseInt(e.endDate.split("-")[0], 10), parseInt(e.endDate.split("-")[1], 10) - 1, parseInt(e.endDate.split("-")[2], 10)),
        goal: e.goal || 0,
        amountOfPrizes: e.amountOfPrizes || 0,
        prizes: e.prizes.map((e: any) => e),
        forceResult: !!e.forceResult,
        finished: !!e.finished,
        ticketsSold: e.ticketsSold || 0,
        transferStatus: e.transferStatus,
        planPaymentStatus: e.planPaymentStatus,
        createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
    }));
}

export async function transferTheRequestAvailableRaffles(id: string, progress?: (progress: number) => void): Promise<TransferHistory> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "transferTheRequestAvailableRaffles", args, progress});
    return {
        id: ret.id,
        totalPrice: ret.totalPrice || 0,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            phone: ret.user.phone,
            cpf: ret.user.cpf === null || ret.user.cpf === undefined ? null : ret.user.cpf,
            birthdate: ret.user.birthdate === null || ret.user.birthdate === undefined ? null : new Date(parseInt(ret.user.birthdate.split("-")[0], 10), parseInt(ret.user.birthdate.split("-")[1], 10) - 1, parseInt(ret.user.birthdate.split("-")[2], 10)),
            seller: ret.user.seller === null || ret.user.seller === undefined ? null : {
                documentFrontImage: {
                    thumb: {
                        width: ret.user.seller.documentFrontImage.thumb.width || 0,
                        height: ret.user.seller.documentFrontImage.thumb.height || 0,
                        url: ret.user.seller.documentFrontImage.thumb.url,
                    },
                    width: ret.user.seller.documentFrontImage.width || 0,
                    height: ret.user.seller.documentFrontImage.height || 0,
                    url: ret.user.seller.documentFrontImage.url,
                },
                documentBackImage: {
                    thumb: {
                        width: ret.user.seller.documentBackImage.thumb.width || 0,
                        height: ret.user.seller.documentBackImage.thumb.height || 0,
                        url: ret.user.seller.documentBackImage.thumb.url,
                    },
                    width: ret.user.seller.documentBackImage.width || 0,
                    height: ret.user.seller.documentBackImage.height || 0,
                    url: ret.user.seller.documentBackImage.url,
                },
                photoHoldingDocument: {
                    thumb: {
                        width: ret.user.seller.photoHoldingDocument.thumb.width || 0,
                        height: ret.user.seller.photoHoldingDocument.thumb.height || 0,
                        url: ret.user.seller.photoHoldingDocument.thumb.url,
                    },
                    width: ret.user.seller.photoHoldingDocument.width || 0,
                    height: ret.user.seller.photoHoldingDocument.height || 0,
                    url: ret.user.seller.photoHoldingDocument.url,
                },
                approved: ret.user.seller.approved,
                bankAccount: ret.user.seller.bankAccount === null || ret.user.seller.bankAccount === undefined ? null : {
                    id: ret.user.seller.bankAccount.id,
                    bankCode: ret.user.seller.bankAccount.bankCode,
                    agency: ret.user.seller.bankAccount.agency,
                    agencyDv: ret.user.seller.bankAccount.agencyDv === null || ret.user.seller.bankAccount.agencyDv === undefined ? null : ret.user.seller.bankAccount.agencyDv,
                    account: ret.user.seller.bankAccount.account,
                    accountDv: ret.user.seller.bankAccount.accountDv === null || ret.user.seller.bankAccount.accountDv === undefined ? null : ret.user.seller.bankAccount.accountDv,
                    type: ret.user.seller.bankAccount.type,
                },
            },
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
                latLng: ret.user.address.latLng === null || ret.user.address.latLng === undefined ? null : {
                    lat: ret.user.address.latLng.lat,
                    lng: ret.user.address.latLng.lng,
                },
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        transferHistoryStatus: ret.transferHistoryStatus,
        transferDate: new Date(parseInt(ret.transferDate.split("-")[0], 10), parseInt(ret.transferDate.split("-")[1], 10) - 1, parseInt(ret.transferDate.split("-")[2], 10)),
    };
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllPendingSellers(pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPendingSellers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        phone: e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        seller: e.seller === null || e.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: e.seller.documentFrontImage.thumb.width || 0,
                    height: e.seller.documentFrontImage.thumb.height || 0,
                    url: e.seller.documentFrontImage.thumb.url,
                },
                width: e.seller.documentFrontImage.width || 0,
                height: e.seller.documentFrontImage.height || 0,
                url: e.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: e.seller.documentBackImage.thumb.width || 0,
                    height: e.seller.documentBackImage.thumb.height || 0,
                    url: e.seller.documentBackImage.thumb.url,
                },
                width: e.seller.documentBackImage.width || 0,
                height: e.seller.documentBackImage.height || 0,
                url: e.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: e.seller.photoHoldingDocument.thumb.width || 0,
                    height: e.seller.photoHoldingDocument.thumb.height || 0,
                    url: e.seller.photoHoldingDocument.thumb.url,
                },
                width: e.seller.photoHoldingDocument.width || 0,
                height: e.seller.photoHoldingDocument.height || 0,
                url: e.seller.photoHoldingDocument.url,
            },
            approved: e.seller.approved,
            bankAccount: e.seller.bankAccount === null || e.seller.bankAccount === undefined ? null : {
                id: e.seller.bankAccount.id,
                bankCode: e.seller.bankAccount.bankCode,
                agency: e.seller.bankAccount.agency,
                agencyDv: e.seller.bankAccount.agencyDv === null || e.seller.bankAccount.agencyDv === undefined ? null : e.seller.bankAccount.agencyDv,
                account: e.seller.bankAccount.account,
                accountDv: e.seller.bankAccount.accountDv === null || e.seller.bankAccount.accountDv === undefined ? null : e.seller.bankAccount.accountDv,
                type: e.seller.bankAccount.type,
            },
        },
        address: e.address === null || e.address === undefined ? null : {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: e.address.latLng === null || e.address.latLng === undefined ? null : {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllSellers(textFilter: string | null, pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        textFilter: textFilter === null || textFilter === undefined ? null : textFilter,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllSellers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        phone: e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        seller: e.seller === null || e.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: e.seller.documentFrontImage.thumb.width || 0,
                    height: e.seller.documentFrontImage.thumb.height || 0,
                    url: e.seller.documentFrontImage.thumb.url,
                },
                width: e.seller.documentFrontImage.width || 0,
                height: e.seller.documentFrontImage.height || 0,
                url: e.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: e.seller.documentBackImage.thumb.width || 0,
                    height: e.seller.documentBackImage.thumb.height || 0,
                    url: e.seller.documentBackImage.thumb.url,
                },
                width: e.seller.documentBackImage.width || 0,
                height: e.seller.documentBackImage.height || 0,
                url: e.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: e.seller.photoHoldingDocument.thumb.width || 0,
                    height: e.seller.photoHoldingDocument.thumb.height || 0,
                    url: e.seller.photoHoldingDocument.thumb.url,
                },
                width: e.seller.photoHoldingDocument.width || 0,
                height: e.seller.photoHoldingDocument.height || 0,
                url: e.seller.photoHoldingDocument.url,
            },
            approved: e.seller.approved,
            bankAccount: e.seller.bankAccount === null || e.seller.bankAccount === undefined ? null : {
                id: e.seller.bankAccount.id,
                bankCode: e.seller.bankAccount.bankCode,
                agency: e.seller.bankAccount.agency,
                agencyDv: e.seller.bankAccount.agencyDv === null || e.seller.bankAccount.agencyDv === undefined ? null : e.seller.bankAccount.agencyDv,
                account: e.seller.bankAccount.account,
                accountDv: e.seller.bankAccount.accountDv === null || e.seller.bankAccount.accountDv === undefined ? null : e.seller.bankAccount.accountDv,
                type: e.seller.bankAccount.type,
            },
        },
        address: e.address === null || e.address === undefined ? null : {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: e.address.latLng === null || e.address.latLng === undefined ? null : {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllUsers(textFilter: string | null, pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        textFilter: textFilter === null || textFilter === undefined ? null : textFilter,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        phone: e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        seller: e.seller === null || e.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: e.seller.documentFrontImage.thumb.width || 0,
                    height: e.seller.documentFrontImage.thumb.height || 0,
                    url: e.seller.documentFrontImage.thumb.url,
                },
                width: e.seller.documentFrontImage.width || 0,
                height: e.seller.documentFrontImage.height || 0,
                url: e.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: e.seller.documentBackImage.thumb.width || 0,
                    height: e.seller.documentBackImage.thumb.height || 0,
                    url: e.seller.documentBackImage.thumb.url,
                },
                width: e.seller.documentBackImage.width || 0,
                height: e.seller.documentBackImage.height || 0,
                url: e.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: e.seller.photoHoldingDocument.thumb.width || 0,
                    height: e.seller.photoHoldingDocument.thumb.height || 0,
                    url: e.seller.photoHoldingDocument.thumb.url,
                },
                width: e.seller.photoHoldingDocument.width || 0,
                height: e.seller.photoHoldingDocument.height || 0,
                url: e.seller.photoHoldingDocument.url,
            },
            approved: e.seller.approved,
            bankAccount: e.seller.bankAccount === null || e.seller.bankAccount === undefined ? null : {
                id: e.seller.bankAccount.id,
                bankCode: e.seller.bankAccount.bankCode,
                agency: e.seller.bankAccount.agency,
                agencyDv: e.seller.bankAccount.agencyDv === null || e.seller.bankAccount.agencyDv === undefined ? null : e.seller.bankAccount.agencyDv,
                account: e.seller.bankAccount.account,
                accountDv: e.seller.bankAccount.accountDv === null || e.seller.bankAccount.accountDv === undefined ? null : e.seller.bankAccount.accountDv,
                type: e.seller.bankAccount.type,
            },
        },
        address: e.address === null || e.address === undefined ? null : {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: e.address.latLng === null || e.address.latLng === undefined ? null : {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function setApproveOrRefuse(id: string, approved: StatusSeller, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        approved: approved,
    };
    const ret = await makeRequest({name: "setApproveOrRefuse", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        seller: ret.seller === null || ret.seller === undefined ? null : {
            documentFrontImage: {
                thumb: {
                    width: ret.seller.documentFrontImage.thumb.width || 0,
                    height: ret.seller.documentFrontImage.thumb.height || 0,
                    url: ret.seller.documentFrontImage.thumb.url,
                },
                width: ret.seller.documentFrontImage.width || 0,
                height: ret.seller.documentFrontImage.height || 0,
                url: ret.seller.documentFrontImage.url,
            },
            documentBackImage: {
                thumb: {
                    width: ret.seller.documentBackImage.thumb.width || 0,
                    height: ret.seller.documentBackImage.thumb.height || 0,
                    url: ret.seller.documentBackImage.thumb.url,
                },
                width: ret.seller.documentBackImage.width || 0,
                height: ret.seller.documentBackImage.height || 0,
                url: ret.seller.documentBackImage.url,
            },
            photoHoldingDocument: {
                thumb: {
                    width: ret.seller.photoHoldingDocument.thumb.width || 0,
                    height: ret.seller.photoHoldingDocument.thumb.height || 0,
                    url: ret.seller.photoHoldingDocument.thumb.url,
                },
                width: ret.seller.photoHoldingDocument.width || 0,
                height: ret.seller.photoHoldingDocument.height || 0,
                url: ret.seller.photoHoldingDocument.url,
            },
            approved: ret.seller.approved,
            bankAccount: ret.seller.bankAccount === null || ret.seller.bankAccount === undefined ? null : {
                id: ret.seller.bankAccount.id,
                bankCode: ret.seller.bankAccount.bankCode,
                agency: ret.seller.bankAccount.agency,
                agencyDv: ret.seller.bankAccount.agencyDv === null || ret.seller.bankAccount.agencyDv === undefined ? null : ret.seller.bankAccount.agencyDv,
                account: ret.seller.bankAccount.account,
                accountDv: ret.seller.bankAccount.accountDv === null || ret.seller.bankAccount.accountDv === undefined ? null : ret.seller.bankAccount.accountDv,
                type: ret.seller.bankAccount.type,
            },
        },
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: ret.address.latLng === null || ret.address.latLng === undefined ? null : {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
