import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import moment from "moment";

// MARK: Implementation
const Strings = new LocalizedStrings({
	ptBR: {
		enum: {
			Gender: {
				male: "Masculino",
    			female: "Feminino",
    			unknown: "Outro",
			},
			TransferHistoryStatus: {
				transferred: "Transferida",
				processing: "Processando",
				requested: "Requerida",
				canceled: "Cancelada",
				done: "Tranferido",
			},
			TicketSaleType: {
				internal: "Interno",
				external: "Externo",
			},
			BankAccountType: {
				contaCorrente: "Conta corrente",
				contaPoupanca: "Conta poupança",
				contaCorrenteConjunta: "Conta corrente conjunta",
				contaPoupancaConjunta: "Conta poupança conjunta",
			},
			TicketStatus: {
				purchased: "Comprado",
				reserved: "Reservado",
			},
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
		},
		errorPaginated: {
			type: "StillLoading",
			message: "Ainda estamos buscando os dados, aguarde.",
		},
		fields: {
			id: "id",
			shortDate:(date: Date) => moment(date).format("L"),
			saleType: "Tipo da venda",
			city: "Cidade",
			gender: "Gênero",
			objective: "Objetivo",
			confirmedDate: "Confirmação do prêmio",
			dontConfirmed: "Não confirmado",
			confirmed: "Confirmado",
			goal: "Meta",
			complementary: "Complemento",
			neighborhood: "Bairro",
			street: "Rua",
			streetNumber: "Número da rua",
			state: "Estado",
			status: "Status",
			zipCode: "CEP",
			refuse: "Recusar",
			accepted: "Aceitar",
			archive: "Arquivar",
			document: "Documentação",
			type: "Tipo",
			slug: "slug",
			imageContainer: "Imagem Container",
			image: "Imagem",
			url: "Url",
			tags: "Tags",
			point: "Pontos",
			pointsOrder: "Ordenação por",
			prerequisite: "Pré-requisito",
			minAmount: "Quantidade miníma",
			diameter: "Diâmetro",
			images: "Imagens",
			phone: "Telefone",
			price: "Preço",
			totalPrice: "Valor total",
			dateTransfer: "Data da solicitação",
			amountOfPrizes: "Quantidade de Prêmios",
			amountOfTicketSolds: "Bilhetes Vendidos",
			productionTime: "Tempo de produção",
			whatsapp: "Whatsapp",
			category: "Categoria",
			nameWinner: "Nome do ganhador",
			nameRaffle: "Nome da rifa",
			name: "Nome",
			bankCode: "Código do banco",
			accountDV: "Dígito da conta",
			agencyDV: "Dígito da agência",
			agency: "Agência",
			typeAccount: "Tipo da conta",
			account: "Conta",
			question: "Pergunta",
			answer: "Resposta",
			winner: "Premiado",
			number: "Número",
			cnpj: "CNPJ",
			minPoints: "Mínimo de pontos",
			level: "Nível",
			userDetails: "Clique para ver mais informações sobre o usuário",
			maxPoints: "Máximo de pontos",
			nick: "Apelido",
			birthdate: "Data de Nascimento",
			prizeDate: "Data do Sorteio",
			cpf: "CPF",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			notFound: "Não encontrado",
			save: "Salvar",
			email: "Email",
			limit: "Limite de bilhetes",
			token: "Token",
			text: "Texto",
			message: "Mensagem",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			recovery: "Recuperar Senha",
			title: "Título",
			result: "Resultado da rifa",
			firstPrize: "1° Prêmio",
			secondPrize: "2° Prêmio",
			thirdPrize: "3° Prêmio",
			fourthPrize: "4° Prêmio",
			fifthPrize: "5° Prêmio",
			contestNumber: "Número do Concurso",
			description: "Descrição",
			createdAt: "Criado em",
			endAt: "Data de término",
			block: "Data de bloqueio",
			user: "Usuário",
			availability: "Aula disponível para",
			prizeNumber: (numberPrize: number) => `Ganhador n° ${numberPrize}`,
		},
		components: {
			table: {
				noMoreResults: "Sem resultados",
				create: "Criar",
				delete: "Deletar",
				details: "Detalhes",
				edit: "Editar",
				confirmedDate: "Confirmação do prêmio",
				dontConfirmed: "Não confirmado",
				confirmed: "Confirmado",
				export: "Exportar",
				accept: "Aceitar",
				reject: "Reject",
				fallbackData: "Não há dados correspondentes",
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				blockAndEditAndDelete: "Editar / Bloquear / Deletar",
				detailsAndblockAndEditAndDelete:
					"Vizualizar / Editar / Bloquear / Deletar",
				detailsAndEditAndDelete: "Editar / Detalhes / Deletar",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
			filter: {
				title: "Filtro",
			},
		},
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},
		filter: {
			all: "Todos",
			createDate: "Data de criação",
			blockeds: "Bloqueados",
			asc: "Menos Pontos",
			desc: "Mais pontos",
			avaliable: "Disponíveis",
		},
		dialogs: {
			deleteContact: (name: string) => ({
				title: "Apagar Contato",
				message:
					"Tem certeza que deseja apagar o contato " +
					name +
					" ?, essa operação não pode ser revertida.",
			}),
			delete: {
				title: "Confirmação de exclusão.",
				message: "Deseja mesmo excluir este usuário?",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},
		loading: "Carregando",
		hiAdmin: "Olá, Administrador(a)",
		error: {
			invalidEmail: "Email inválido",
			invalidPhone: "Telefone inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			nameIsEmpty: "O nome está vazio",
			shortName: "O texto deve ser mais longo",
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
			mustWaitWhileStillLoading: "Ainda em carregamento, aguarde um momento.",
			missingImage: "É necessario anexar a imagem",
			missingDate: "Insira uma data.",
			invalidDate: "Data inválida.",
			missingType: "Escolha um tipo.",
			aleredySelected: "Já Selecionado!",
		},
		nav: {
			title: "Rifei",
			home: "Inicio",
			pendingUsers: "Usuários Pendentes",
			pendingPrizes: "Prêmios Pendentes",
			tags: "Tags",
			plan: "Planos",
			sellers: "Vendedores",
			raffles: "Rifas",
			rafflesResult: "Resultado das Rifas",
			banners: "Banners",
			faq: "FAQ",
			transferHistory: "Solicitações de Transferências",
			users: "Usuários",
			adminUsers: "Usuários Admin",
			logout: "Sair",
			configure: "Configurações",
			about: "Sobre",
			disconected: "Você está descontectado da internet",
		},
		pageLogin: {
			pagetitle: "Rifei",
			title: "Acesso",
			buttonLogin: "Entrar",
			noAccess: "Ainda não possui acesso?",
			requestAccess: "Solicite Aqui",
			recoveryPassword: "Esqueceu a senha?",
			messageFooter: "Desenvolvido de ponta a ponta pela Startapp",
			needHelp: "Precisa de ajuda?",
		},
		home: {
			welcome: "Bem vindo ao dashboard do Rifei",
		},
		pendingUsers: {
			table: {
				title: "Tabela de vendedores pendentes",
			},
			details: {
				title: "Detalhes de vendedores pendentes",
			},
		},
		pendingPrizes: {
			table: {
				title: "Tabela de prêmios pendentes",
			},
			details: {
				title: "Detalhes do prêmio pendente",
				copyCode: (isCopied: boolean) => isCopied ? "LINK COPIADO!!" : "Copiar link de confirmação do prêmio",
			},

		},
		tickets: {
			table: {
				title: "Tabela de bilhetes",
			},
			details: {
				title: "Detalhes do bilhete",
			},
		},
		raffles: {
			filterPlaceHolder: "Nome da Rifa",
			success: "Operação realizada com sucesso!",
			table: {
				title: "Tabela de rifas",
			},
			details: {
				title: "Detalhes da rifa",
				finished: "Finalizado",
				onWay: "Em andamento",
			},
			delete: {
				title: "Deletar rifa",
				message: "Tem certeza que deseja deletar esta rifa?",
			},
			detailsPrize: {
				title: "Detalhes do prêmio",
			},
		},
		transferHistory: {
			table: {
				title: "Tabela de transferências",
			},
			details: {
				title: "Detalhes da transferência",
				transferData: "Dados da transferência",
				userData: "Dados do vendedor",
				confirmTransfer: "Confirmar Transferência",
				raffleData: "Dados das rifas",
				bankAccount: "Dados da conta bancária",
			},
			dialogConfirmTransfer: {
				title: "Confirmar transferência?",
				message: (name: string) => `Tem certeza que deseja confirma a transferência para ${name}`,
			},
		},
		rafflesResult: {
			title: "Criar resultado da rifa",
			success: "Operação realizada com sucesso!",
			table: {
				title: "Tabela de resultado das rifas",
			},
		},
		sellers: {
			filterPlaceholder: "Nome do vendedor",
			table: {
				title: "Tabela de vendedores",
			},
			details: {
				title: "Detalhes do vendedor",
				header: { name: "Nome", email: "Email" },
			},
			blockDialogUser: {
				title: (blockUser: boolean) =>
					`${blockUser ? "Bloquear" : "Desbloquear"} usuário`,
				message: (blockUser: boolean) =>
					`Tem certeza que deseja ${
						blockUser ? "bloquear" : "desbloquear"
					} este usuário?`,
			},
		},
		banner: {
			create: {
				title: "Criar banner",
				createButton: "Criar",
				success: "Banner criado com sucesso!",
			},
			edit: {
				title: "Edição do banner",
				saveChanges: "Salvar",
				onClick: "O banner foi editado",
				error: "A imagem é obrigatória",
				success: (title: string) =>
					`${title} modificado com sucesso!`,
			},
			delete: {
				title: "Deletar banner",
				message: "Tem certeza que deseja deletar este banner?",
			},
			table: {
				title: "Tabela de banner",
			},
			details: {
				title: "Detalhes do banner",
				header: { name: "Nome", email: "Email" },
			},
		},
		plan: {
			create: {
				title: "Criar plano",
				createButton: "Criar",
				success: "Plano criada com sucesso!",
			},
			edit: {
				title: (title: string) => `Edição da plano ${title}`,
				saveChanges: "Salvar",
				onClick: "O plano foi editado",
				success: (title: string) =>
					`${title} modificado com sucesso!`,
			},
			delete: {
				title: "Deletar plano",
				message: "Tem certeza que deseja deletar este plano?",
			},
			table: {
				title: "Tabela de planos",
			},
			details: {
				title: "Detalhes do planos",
				header: { name: "Nome", email: "Email" },
			},
		},
		FAQ: {
			create: {
				title: "Criar FAQ",
				createButton: "Criar",
				success: "FAQ criado com sucesso!",
			},
			edit: {
				title: "Edição do FAQ",
				saveChanges: "Salvar",
				onClick: "O FAQ foi editado",
				success: "modificado com sucesso!",
			},
			delete: {
				title: "Deletar FAQ",
				message: "Tem certeza que deseja deletar este FAQ?",
			},
			table: {
				title: "Tabela de FAQ",
			},
			details: {
				title: "Detalhes do FAQ",
				header: { name: "Nome", email: "Email" },
			},
		},
		user: {
			filterPlaceholder: "Nome do Usuário",
			table: {
				title: "Tabela de usuários",
			},
			details: {
				title: "Detalhes do usuário",
				header: { name: "Nome", email: "Email" },
			},
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		register: {
			success: "Administrador criado com sucesso",
			successUser: "Usúario criado com sucesso",
			successLevel: "Nível criado com sucesso",
		},
		receivePrize: {
			title: "Confirmação de recebimento do prêmio",
			winner: "Vencedor:",
			name: "Nome:",
			price: "Preço:",
			prize: "Prêmio:",
			objective: "Objetivo:",
			raffleNumber: "Número da rifa:",
			confirm: "Confirmar",
			confirmed: "Recebimento do prêmio confirmado!",
			notFound: "Ticket não foi encontrado.",
		},
		recoveryPage: {
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
	},
});

export default Strings;
