import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	buttonCopy: {
		display: "flex",
		alignSelf: "center",
		width: "30%",
		marginTop: "10px",
		marginBottom: "20px",
	},
}));
