import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import Home from "./Home";
import PendingUsers from "./PendingUsers";
import Sellers from "./Sellers";
import Users from "./Users";
import Plans from "./Plans";
import FAQ from "./FAQ";
import Raffles from "./Raffles";
import PendingPrizes from "./PendingPrizes";
import RaffleResult from "./RaffleResult";
import Banners from "./Banners";
import TransferHistory from "./TransferHistory";

export const Dashboard = () => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/dashboard" component={Home} />
				<Route path="/dashboard/pendingUsers" component={PendingUsers} />
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/sellers" component={Sellers} />
				<Route path="/dashboard/plans" component={Plans} />
				<Route path="/dashboard/faq" component={FAQ} />
				<Route path="/dashboard/raffles" component={Raffles} />
				<Route path="/dashboard/pendingPrizes" component={PendingPrizes} />
				<Route path="/dashboard/rafflesResult" component={RaffleResult} />
				<Route path="/dashboard/banners" component={Banners} />
				<Route path="/dashboard/transferHistory" component={TransferHistory} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
