import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import CreateView from "./CreateView";

interface IProps { }

const RaffleResult: React.FC<IProps> = () => (
	<Switch>
		<Route
			exact
			path="/dashboard/rafflesResult"
			component={TableView}
		/>
		<Route
			path="/dashboard/rafflesResult/create"
			component={CreateView}
		/>
	</Switch>
);

export default observer(RaffleResult);
