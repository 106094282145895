import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));

	const history = useHistory();
	const onSubmit = async () => {
		await store.create(() => history.goBack());
	};

	return (
		<CardForm
			title={Strings.FAQ.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmit}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.FAQ.create.createButton
					)}
				</Button>
			}
		>
			<CardContent>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("question")}
					helperText={store.fieldError.getFieldError("question")}
					className={classes.textField}
					variant="standard"
					type="text"
					label={Strings.fields.question}
					{...store.formController.field("question")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("answer")}
					helperText={store.fieldError.getFieldError("answer")}
					className={classes.textField}
					variant="standard"
					type="text"
					label={Strings.fields.answer}
					{...store.formController.field("answer")}
				/>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
