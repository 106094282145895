import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { grey, green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			width: "100%",
			height: "20vh",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: grey[800],
		},
		pageTitle: {
			textAlign: "center",
			margin: "10px",
			color: grey[50],
			fontSize: "30px",
			fontWeight: "bold",
		},
		pageContainer: {
			margin: 0,
			width: "100%",
			display: "flex",
			justifyContent: "center",
		},
		pageContent: {
			width: "100%",
			display: "flex",
			paddingTop: "50px",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "rgb(235, 235, 235)",
			flexDirection: "column",
		},
		actions: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		button: {
			marginBottom: 10,
			color: grey[50],
			backgroundColor: green[500],
		},
		title: {
			textAlign: "center",
			marginBottom: "0.5em",
			fontWeight: "bold",
		},
		label: {
			marginBottom: "0.5em",
			marginTop: "1em",
			fontWeight: "bold",
		},
		field: {
			marginTop: "0.5em",
			fontSize: 16,
		},
		image: {
			width: "100%",
			padding: 40,
			borderRadius: 10,
		},
		confirmContent: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		card: {
			"width": "350px",
			"padding": theme.spacing(5, 2, 0, 2),
			"marginBottom": "30px",
			"boxShadow": "0px 0px",
			"background": grey[50],

			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiOutlinedInput-root": {
				maxWidth: "364px",
			},
		},
	}),
);

export default useStyles;
