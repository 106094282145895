// MARK: API
import * as API from "@startapp/rifei-admin-api";

// MARK: Libraries
import { LocalizedStringsMethods } from "localized-strings";

const productionUrl = "https://api.rifeiapp.com.br/admin";
const stagingUrl = "https://api.rifeiapp.com.br/admin-staging";

const bootAPI = (strings: LocalizedStringsMethods) => {
	let apiURL: string;

	switch (process.env.NODE_ENV) {
		case "production": {
			apiURL = productionUrl;
			break;
		}

		case "staging":
		default: {
			apiURL = stagingUrl;
		}
	}

	API.setStrings(strings);
	API.setUrl(apiURL);
};

export default API;

export { bootAPI };
