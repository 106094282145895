import API from "../../modules/API";
import UIStore from "../../stores/UIStore";
import Strings from "../../modules/Strings";
import LoaderShelf from "../../shelves/LoaderShelf";
import {
	action,
	makeObservable,
	observable,
	runInAction,
} from "mobx";

export default class Store {
	private uiStore: UIStore;
	public loader: LoaderShelf;

	public drawnTicket: API.DrawnTicket | null = null;
	public isLoading: boolean;
	private confirmId: string;

	constructor(uiStore: UIStore, confirmId: string) {
		this.uiStore = uiStore;
		this.confirmId = confirmId;
		this.loader = new LoaderShelf();
		this.getPrizeConfirmation();

		makeObservable(this, {
			drawnTicket: observable,
			getPrizeConfirmation: action,
		});
	}

	public getPrizeConfirmation = async () => {
		try{
			this.loader.start();
			const resultTicket = await API.getPrizeConfirmation(this.confirmId);
			runInAction(() => {
				this.drawnTicket = resultTicket;
			});
		}catch(error){
			this.uiStore.showSnackbar(Strings.error.default);
		}finally{
			this.loader.end();
		}
	};

	public confirmPrize = async () => {
		try{
			if(this.drawnTicket){
				await API.confirmPrize(this.drawnTicket.id);
				this.getPrizeConfirmation();
			}
		}catch(error){
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};
}
