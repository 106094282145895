import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import CardApprove from "../../../../components/CardApprove";
import LinearProgress from "../../../../components/LinearProgress";
import { format } from "date-fns";
import API from "../../../../modules/API";
import { useGlobalStore } from "../../../../pages/context";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore, id, history));

	const refuse = () => store.approvedOrrefuse(() => history.push("/dashboard/pendingUsers"), API.StatusSeller.rejected);

	const approved = () => store.approvedOrrefuse(() => history.push("/dashboard/pendingUsers"), API.StatusSeller.accepted);

	return (
		<CardForm title={Strings.pendingUsers.details.title}>
			{!store.pendingUser ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow label={Strings.fields.name} value={store.pendingUser.name} />
					<DetailRow label={Strings.fields.email} value={store.pendingUser.email} />
					{store.pendingUser.cpf && (
						<DetailRow label={Strings.fields.cpf} value={store.pendingUser.cpf} />
					)}
					{store.pendingUser.birthdate && (
						<DetailRow
							label={Strings.fields.birthdate}
							value={format(store.pendingUser.birthdate, "dd/MM/yyyy")}
						/>
					)}
					<DetailRow label={Strings.fields.phone} value={store.pendingUser.phone} />
					{store.pendingUser.address && (
						<>
							<DetailRow label={Strings.fields.state} value={store.pendingUser.address.state} />
							<DetailRow label={Strings.fields.city} value={store.pendingUser.address.city} />
							<DetailRow label={Strings.fields.neighborhood} value={store.pendingUser.address.neighborhood} />
							<DetailRow label={Strings.fields.street} value={store.pendingUser.address.street} />
							<DetailRow label={Strings.fields.streetNumber} value={store.pendingUser.address.streetNumber} />
							<DetailRow label={Strings.fields.zipCode} value={store.pendingUser.address.zipcode} />
						</>
					)}
					{store.pendingUser.seller
						&& (
							<CardApprove
								loading={store.loader.isLoading}
								approved={approved} refuse={refuse}
								backImage={store.pendingUser.seller.documentBackImage.url}
								frontImage={store.pendingUser.seller.documentFrontImage.url}
								holdingImage={store.pendingUser.seller.photoHoldingDocument.url}
							/>
						)}
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
