import { makeStyles } from "@material-ui/core";
import { grey, red, green } from "@material-ui/core/colors";

export const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 4,
	},
	container: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		padding: "5px",
		borderRadius: 10,
		borderStyle: "solid",
		alignItems: "center",
		borderColor: grey[500],
		justifyContent: "center",
		border: 0.2,
		marginTop: 16,
	},
	boxContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		marginTop: 16,
	},
	approved: {
		width: "20%",
		backgroundColor: green[400],
		color: "white",
	},
	refuse: {
		width: "20%",
		backgroundColor: red[400],
		color: "white",
	},
	image: {
		padding: "5px",
		borderRadius: 10,
	},
}));
