import * as React from "react";
import "./style.ts";

// MARK: Material
import { Box, CardMedia } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "./style";
import Button from "../Button";
import LinearProgress from "../LinearProgress";
import Strings from "../../modules/Strings";

interface IProps {
	backImage: string;
	frontImage: string;
	holdingImage: string;
	refuse: () => void;
	approved: () => void;
	loading: boolean;
}

const DetailsView: React.FC<IProps> = (props) => {
	const { holdingImage, backImage, frontImage, approved, refuse, loading } = props;
	const classes = useStyles();

	return (
		<>
			{loading ? <LinearProgress />
				: (
					<Box className={classes.root}>
						<Box className={classes.container}>
							<a href={backImage} target="_blank" rel="noreferrer">
								<CardMedia component="img" className={classes.image} image={backImage} />
							</a>
							<a href={holdingImage} target="_blank" rel="noreferrer">
								<CardMedia component="img" className={classes.image} image={holdingImage} />
							</a>
							<a href={frontImage} target="_blank" rel="noreferrer">
								<CardMedia component="img" className={classes.image} image={frontImage} />
							</a>
						</Box>
						<Box className={classes.boxContainer}>
							<Button onClick={refuse} className={classes.refuse}>
								{Strings.fields.refuse}
							</Button>
							<Button onClick={approved} className={classes.approved}>
								{Strings.fields.accepted}
							</Button>
						</Box>
					</Box>
				)}
		</>
	);
};

export default observer(DetailsView);
