import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import CreateView from "./CreateView";
import EditorView from "./EditorView";
interface IProps { }

const Banners: React.FC<IProps> = () => (
	<Switch>
		<Route
			exact
			path="/dashboard/banners"
			component={TableView}
		/>
		<Route
			path="/dashboard/banners/create"
			component={CreateView}
		/>
		<Route
			path="/dashboard/banners/edit/:id"
			component={EditorView}
		/>
	</Switch>
);

export default observer(Banners);
