import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
		borderStyle: "solid",
		border: 0.2,
		borderColor: grey[500],
		borderRadius: 10,
		marginTop: 4,
	},
	container: {
		display: "flex",
		flexDirection: "row",
		width: "35%",
		padding: "5px",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 16,
		marginBottom: 16,
	},
	boxContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		marginTop: 16,
	},
	image: {
		padding: "5px",
		borderRadius: 10,
	},
}));
