import React from "react";

import { useHistory, useParams } from "react-router";
import { observer, useLocalStore } from "mobx-react-lite";

import {
	CardContent,
	Button,
	TextField,
	Box,
} from "@material-ui/core";

import { useGlobalStore } from "../../../context";

import Strings from "../../../../modules/Strings";

import CardForm from "../../../../components/CardForm";
import { PageContainer } from "../../../../components/PageContainer";

import Store from "./store";

import useStyles from "./styles";
import FileCase from "../../../../components/FileCase";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	const { field } = store.formController;
	const classes = useStyles();

	return (
		<PageContainer>
			<CardForm
				title={Strings.banner.edit.title}
				loading={store.loader.isLoading}
				actionsFooter={
					<Button
						variant="contained"
						onClick={() => {
							store.edit();
						}}
					>
						{Strings.banner.edit.saveChanges}
					</Button>
				}
			>
				<CardContent>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("title")}
						helperText={store.fieldError.getFieldError("title")}
						className={classes.textField}
						variant="standard"
						type="text"
						label={Strings.fields.title}
						{...field("title")}
						{...store.formController.field("title")}
					/>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("message")}
						helperText={store.fieldError.getFieldError("message")}
						className={classes.textField}
						variant="standard"
						type="tel"
						label={Strings.fields.message}
						{...field("message")}
						{...store.formController.field("message")}
					/>
					<Box className={classes.fileCases}>
						<FileCase title={Strings.fields.image} {...store.image.toCase()} />
						<FileCase title={Strings.fields.imageContainer} {...store.contentImage.toCase()} />
					</Box>
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};
export default observer(EditorView);
