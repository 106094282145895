import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import Store from "./store";
import { useGlobalStore } from "../context";
import LinearProgress from "../../components/LinearProgress";
import { Formatting } from "../../modules/Formatting";
import Strings from "../../modules/Strings";
import {
	Card,
	CardContent,
	CardActions,
	Typography,
	Paper,
	CardMedia,
	Button,
} from "@material-ui/core";

const ReceivePrize = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const classes = useStyles();
	const store = useLocalStore(() => new Store(uiStore ,id));

	return(
		<>
			{store.loader.isLoading ? (
				<LinearProgress />
			) : (
				<Paper className={classes.pageContent} elevation={0}>
					<Card className={classes.card}>
						{!store.drawnTicket ? (
							<CardContent className={classes.confirmContent}>
								<CardMedia component="img" className={classes.image} image={"/logo_rifei.svg"} />
								<Typography variant="h6" className={classes.title}>{Strings.receivePrize.notFound}</Typography>
								<img src={"/cancel.svg"} width={60}/>
							</CardContent>
						) : (
							store.drawnTicket.confirmed ? (
								<CardContent className={classes.confirmContent}>
									<CardMedia component="img" className={classes.image} image={"/logo_rifei.svg"} />
									<Typography variant="h6" className={classes.title}>{Strings.receivePrize.confirmed}</Typography>
									<img src={"/check_circle.svg"} width={60}/>
								</CardContent>
							) : (
								<>
									<CardContent>
										<CardMedia component="img" className={classes.image} image={"/logo_rifei.svg"} />
										<Typography variant="h6" className={classes.title}>{Strings.receivePrize.title}</Typography>
										<Typography variant="body2" className={classes.label}>{Strings.receivePrize.winner}</Typography>
										<Typography className={classes.field}>{store.drawnTicket.raffleTicket.owner.name}</Typography>
										<Typography variant="body2" className={classes.label}>{Strings.receivePrize.raffleNumber}</Typography>
										<Typography className={classes.field}>{store.drawnTicket.raffleTicket.number}</Typography>
										<Typography variant="body2" className={classes.label}>{Strings.receivePrize.price}</Typography>
										<Typography className={classes.field}>{Formatting.currencyForBR(store.drawnTicket.raffle.ticketPrice)}</Typography>
										<Typography variant="body2" className={classes.label}>{Strings.receivePrize.prize}</Typography>
										<Typography className={classes.field}>
											{store.drawnTicket.raffle.prizes[store.drawnTicket.prizeNumber]}
										</Typography>
										<Typography variant="body2" className={classes.label}>{Strings.receivePrize.objective}</Typography>
										<Typography className={classes.field}>{store.drawnTicket.raffle.objective}</Typography>
									</CardContent>
									<CardActions className={classes.actions}>
										<Button
											className={classes.button}
											onClick={store.confirmPrize}
										>
											{Strings.receivePrize.confirm}
										</Button>
									</CardActions>
								</>
							)
						)}
					</Card>
				</Paper>
			)}
		</>
	);
};

export default observer(ReceivePrize);
