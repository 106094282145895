import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	Typography,
	FormControl,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from "@material-ui/core";
import {
	Search as SearchIcon,
} from "@material-ui/icons";
import strings from "../../modules/Strings";

interface IProps {
	name: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	onClick: () => void;
	placeHolder?: string;
}

export const Filter: React.FC<IProps> = observer((props) => {
	const { name, onChange, value, onClick, placeHolder } = props;
	const componentStrings = strings.components.filter;

	return (
		<Box
			style={{
				background: "white",
				margin: "30px 0",
				maxWidth: 400,
				display: "flex",
				flexDirection: "column",
				padding: 10,
				borderRadius: 10,

			}}>
			<Typography>
				{componentStrings.title}
			</Typography>

			<FormControl variant="outlined" size="small">
				<OutlinedInput
					value={value}
					onChange={onChange}
					name={name}
					placeholder={placeHolder}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								edge="end"
								onClick={onClick}
							>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					}
				/>

			</FormControl>
		</Box>
	);
});
