import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import DetailsView from "./DetailsView";

interface IProps { }

const PendingUsers: React.FC<IProps> = () => (
	<Switch>
		<Route
			exact
			path="/dashboard/pendingUsers"
			component={TableView}
		/>
		<Route
			path="/dashboard/pendingUsers/details/:id"
			component={DetailsView}
		/>
	</Switch>
);

export default observer(PendingUsers);
