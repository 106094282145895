import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import Store from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import { Button, IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { Edit, Delete, Add as AddIcon } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import { useStyles } from "./styles";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const classes = useStyles();
	const tableStore = useLocalStore(() => new Store(uiStore, history));

	const pushToCreate = useCallback(() => history.push("banners/create"), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`banners/edit/${id}`),
		[history],
	);

	const handleDelete = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.banner.delete.message,
					title: Strings.banner.delete.title,
				},
				() => tableStore.delete(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.banner.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.title,
					Strings.fields.message,
					Strings.components.table.detailsAndDelete,
				]}
				renderItem={(item) => (
					<TableRow hover key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>
							<Typography className={classes.textEllipsis}>
								{item.title}
							</Typography>
						</TableCell>
						<TableCell align={"center"}>
							<Typography className={classes.textEllipsis}>
								{item.message}
							</Typography>
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
							<IconButton onClick={() => handleDelete(item.id)}>
								<Delete />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<Button startIcon={<AddIcon />} onClick={pushToCreate}>
						{Strings.actions.create}
					</Button>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
