import UIStore from "../../../../stores/UIStore";
import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { makeObservable, observable } from "mobx";
import { Errors } from "../../../../modules/Errors";

export default class Store {
	private router: IRouterPusher;
	public user: API.User | null = null;
	private uiStore: UIStore;

	constructor(id: string, router: IRouterPusher) {
		this.router = router;
		this.getUser(id);
		makeObservable(this, {
			user: observable,
		});
	}

	public getUser = async (id: string) => {
		try {
			this.user = await API.getUser(id);
		} catch (error) {
			this.uiStore.showErrorSnackbar(Errors.handleError(error));
		}
	};
}
