import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
	InputAdornment,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));

	const history = useHistory();
	const onSubmit = async () => {
		await store.create(() => history.goBack());
	};

	return (
		<CardForm
			title={Strings.plan.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmit}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.plan.create.createButton
					)}
				</Button>
			}
		>
			<CardContent>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("title")}
					helperText={store.fieldError.getFieldError("title")}
					className={classes.textField}
					variant="standard"
					type="text"
					label={Strings.fields.title}
					{...store.formController.field("title")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("limit")}
					helperText={store.fieldError.getFieldError("limit")}
					className={classes.textField}
					variant="standard"
					type="tel"
					label={Strings.fields.limit}
					{...store.formController.field("limit")}
				/>
				<TextField
					label={Strings.fields.price}
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("price")}
					helperText={store.fieldError.getFieldError("price")}
					{...store.formController.field("price")}
					className={classes.textField}
					type="number"
					variant="standard"
					InputProps={{
						startAdornment: <InputAdornment position="start">R$</InputAdornment>,
					  }}
				/>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
