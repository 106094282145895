import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import { format } from "date-fns";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, history));

	return (
		<CardForm title={Strings.user.details.title}>
			{!store.user ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow label={Strings.fields.name} value={store.user.name} />
					<DetailRow label={Strings.fields.email} value={store.user.email} />
					{store.user.cpf && (
						<DetailRow label={Strings.fields.cpf} value={store.user.cpf} />
					)}
					{store.user.birthdate && (
						<DetailRow
							label={Strings.fields.birthdate}
							value={format(store.user.birthdate, "dd/MM/yyyy")}
						/>
					)}
					<DetailRow label={Strings.fields.phone} value={store.user.phone} />
					{store.user.address && (
						<>
							<DetailRow label={Strings.fields.state} value={store.user.address.state} />
							<DetailRow label={Strings.fields.city} value={store.user.address.city} />
							<DetailRow label={Strings.fields.neighborhood} value={store.user.address.neighborhood} />
							<DetailRow label={Strings.fields.street} value={store.user.address.street} />
							<DetailRow label={Strings.fields.streetNumber} value={store.user.address.streetNumber} />
							<DetailRow label={Strings.fields.zipCode} value={store.user.address.zipcode} />
						</>
					)}
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
