import { makeObservable, observable } from "mobx";

import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";

import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";

import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export default class Store extends ModelStore<API.Plan> {
	protected router: IRouteReplacer;

	public image: API.UncertainImage | null = null;

	public fieldError: FieldErrorShelf<API.EditPlan>;
	public loader: LoaderShelf;

	public formController = new FormStore({
		limit: "",
		title: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		makeObservable(this, {
			formController: observable,
			image: observable,
		});
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
	}

	protected getModel(id: string) {
		return API.getPlan(id);
	}

	protected afterModelFetch(model: API.Plan) {
		this.formController = new FormStore({
			title: model.title || "",
			limit: model.limit.toString() || "",
		});
	}

	public edit = async (onSuccess: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();
			this.fieldError.clearErrors();

			const {
				title,
				limit,
			} = this.formController.getValues();
			const plan = await API.editPlan(this.id, {
				title,
				limit: parseInt(limit),
			});
			onSuccess();
			this.uiStore.showSnackbar(Strings.plan.edit.success(plan.title));
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
