import { makeAutoObservable } from "mobx";

import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";

import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import FileStore from "../../../../stores/FileStore";

export default class Store {
	private uiStore: UIStore;

	public loader: LoaderShelf;
	public fieldError: FieldErrorShelf<API.NewBanner>;
	public contentImage: FileStore;
	public image: FileStore;

	public formController = new FormStore({
		title: "",
		message: "",
	});

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		this.fieldError = new FieldErrorShelf();
		this.image = new FileStore(this.uiStore, "image");
		this.contentImage = new FileStore(this.uiStore, "image");
		makeAutoObservable(this);
	}

	public create = async (onSuccessCallback: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}
			const image = this.image.getUncertainfiedImage();
			const contentImage = this.contentImage.getUncertainfiedImage();

			if (!image) {
				return this.uiStore.showErrorSnackbar(
					Strings.banner.edit.error,
				);
			}


			this.loader.start();
			this.fieldError.clearErrors();
			const { title, message } = this.formController.getValues();
			await API.createBanner({
				title,
				message,
				image,
				contentImage,
			});

			onSuccessCallback();
			this.uiStore.showSnackbar(Strings.banner.create.success);
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
