import React from "react";

import { useHistory, useParams } from "react-router";
import { observer, useLocalStore } from "mobx-react-lite";

import {
	CardContent,
	Button,
	TextField,
} from "@material-ui/core";

import { useGlobalStore } from "../../../context";

import Strings from "../../../../modules/Strings";

import CardForm from "../../../../components/CardForm";
import { PageContainer } from "../../../../components/PageContainer";

import Store from "./store";

import useStyles from "./styles";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	const { field } = store.formController;
	const classes = useStyles();

	const onSubmit = async () => {
		await store.edit(() => history.goBack());
	};

	return (
		<PageContainer>
			<CardForm
				title={Strings.plan.edit.title(store.model?.title)}
				loading={store.loader.isLoading}
				actionsFooter={
					<Button
						variant="contained"
						onClick={() => {
							onSubmit();
						}}
					>
						{Strings.plan.edit.saveChanges}
					</Button>
				}
			>
				<CardContent>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("title")}
						helperText={store.fieldError.getFieldError("title")}
						className={classes.textField}
						variant="standard"
						type="text"
						label={Strings.fields.title}
						{...field("title")}
						{...store.formController.field("title")}
					/>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("limit")}
						helperText={store.fieldError.getFieldError("limit")}
						className={classes.textField}
						variant="standard"
						type="tel"
						label={Strings.fields.limit}
						{...field("limit")}
						{...store.formController.field("limit")}
					/>
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};
export default observer(EditorView);
