import * as React from "react";
import "./style.ts";
import { Box, CardMedia } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "./style";

interface IProps {
	images: string[];
}

const DetailsView: React.FC<IProps> = (props) => {
	const { images } = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.container}>
				{images.map((image) => (
					<a key={image} target="_blank" href={image} rel="noreferrer">
						<CardMedia component="img" className={classes.image} image={image} />
					</a>
				))}
			</Box>
		</Box>
	);
};

export default observer(DetailsView);
