import api from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import AuthStore from "../../../../stores/AuthStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class Store extends PaginatedListStore<api.TransferHistory> {
	private authStore: AuthStore;
	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
	}

	protected getDataItemsPerPage(page: number): Promise<api.TransferHistory[]> {
		return api.getRequestTransferHistoryPaginated(page);
	}
}
