import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import { useGlobalStore } from "../../../context";
import API from "../../../../modules/API";
import { Tooltip } from "@material-ui/core";

const TicketDetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore, id, history));

	const pushToDetails = () => {
		if (store.ticket && store.ticket.owner.id) {
			history.push(`/dashboard/users/details/${store.ticket.owner.id}`);
		}
	};


	return (
		<CardForm title={Strings.tickets.details.title}>
			{!store.ticket ? (
				<LinearProgress />
			) : (
				<>
					{store.ticket.id !== null
						&& (
							<Tooltip title={Strings.fields.userDetails}>
								<a style={{ cursor: "pointer" }} onClick={pushToDetails}>
									<DetailRow label={Strings.fields.id} value={store.ticket.owner.id || ""} />
								</a>
							</Tooltip>
						)
					}
					<DetailRow label={Strings.fields.name} value={store.ticket.owner.name} />
					<DetailRow label={Strings.fields.email} value={store.ticket.owner.email} />
					<DetailRow label={Strings.fields.phone} value={store.ticket.owner.phone} />
					<DetailRow label={Strings.fields.saleType} value={API.translateTicketSaleType(store.ticket.saleType)} />
					<DetailRow label={Strings.fields.number} value={store.ticket.number} />
					<DetailRow label={Strings.fields.status} value={API.translateTicketStatus(store.ticket.status)} />
				</>
			)}
		</CardForm>
	);
};
export default observer(TicketDetailsView);
