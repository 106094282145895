import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import DetailsView from "./DetailsView";

const TransferHistory: React.FC = () => (
	<Switch>
		<Route
			exact
			path="/dashboard/transferHistory"
			component={TableView}
		/>
		<Route
			path="/dashboard/transferHistory/details/:id"
			component={DetailsView}
		/>
	</Switch>
);

export default observer(TransferHistory);
