import { makeObservable, observable } from "mobx";

import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";

import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";

import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export default class Store extends ModelStore<API.FAQ> {
	protected router: IRouteReplacer;

	public fieldError: FieldErrorShelf<API.NewFAQ>;
	public loader: LoaderShelf;

	public formController = new FormStore({
		answer: "",
		question: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		makeObservable(this, {
			formController: observable,
		});
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
	}

	protected getModel(id: string) {
		return API.getFAQById(id);
	}

	protected afterModelFetch(model: API.FAQ) {
		this.formController = new FormStore({
			answer: model.answer || "",
			question: model.question || "",
		});
	}

	public edit = async (onSuccess: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			const {
				answer,
				question,
			} = this.formController.getValues();

			if (question.length < 8) {
				this.fieldError.addError({
					message: Strings.error.shortName,
					field: "question",
				});
				return;
			}

			if (answer.length < 8) {
				this.fieldError.addError({
					message: Strings.error.shortName,
					field: "answer",
				});
				return;
			}

			this.loader.start();
			await API.editFAQ(this.id, {
				answer,
				question,
			});
			onSuccess();
			this.uiStore.showSnackbar(Strings.FAQ.edit.success);
		} catch (error) {
			this.fieldError.clearErrors();
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
