import api from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import Strings from "../../../../modules/Strings";
import AuthStore from "../../../../stores/AuthStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class Store extends PaginatedListStore<api.FAQ> {
	private authStore: AuthStore;
	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
	}

	protected getDataItemsPerPage(page: number): Promise<api.FAQ[]> {
		return api.getAllFAQ(page);
	}

	public delete = async (id: string) => {
		try {
			await api.deleteFAQ(id);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
			this.fetchPage(0);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};
}
