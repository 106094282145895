import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
	createStyles({
		field: {
			fontSize: 24,
			marginBottom: "1.5em",
			marginTop: "1.5em",
			fontWeight: "bold",
		},
		divider: {
			marginBottom: "10px",
			marginTop: "10px",
		},
		title: {
			fontSize: 18,
			fontWeight: "bold",
		},
		button: {
			display: "flex",
			alignSelf: "center",
			width: "30%",
			color: "white",
			backgroundColor: "#576D6E",
			marginTop: "10px",
			marginBottom: "20px",
		},
	}),
);
