import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import { Formatting } from "../../../../modules/Formatting";
import Store from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new Store(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`transferHistory/details/${id}`),
		[history],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.transferHistory.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.totalPrice,
					Strings.fields.dateTransfer,
					Strings.components.table.details,
				]}
				renderItem={(item) => (
					<TableRow hover key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{Formatting.currencyForBR(item.totalPrice)}</TableCell>
						<TableCell align={"center"}>{Strings.fields.shortDate(item.transferDate)}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
