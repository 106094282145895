import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
	createStyles({
		field: {
			fontSize: 24,
			marginBottom: "1.5em",
			marginTop: "1.5em",
			fontWeight: "bold",
		},
		tagFinished: {
			width: "25%",
			fontWeight: "bolder",
			fontSize: 16,
			display: "flex",
			alignSelf: "center",
			color: "white",
			backgroundColor: "#83c5be",
			marginBottom: "1em",
		},
		buttonCopy: {
			display: "flex",
			alignSelf: "center",
			width: "30%",
			marginTop: "10px",
			marginBottom: "20px",
		},
		tagPending: {
			width: "25%",
			fontWeight: "bolder",
			fontSize: 16,
			display: "flex",
			alignSelf: "center",
			color: "white",
			backgroundColor: "#ffba08",
			marginBottom: "1em",
		},
	}),
);
